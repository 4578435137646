.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-125);
  }

  & > .bigButtonContainer {
    flex: 1;
  }

  & > .pointsContainer {
    flex: 1;

    @media (--viewport-md) {
      margin-top: var(--size-32);
    }
  }
}

.bigButtonContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  @media (--viewport-md) {
    gap: var(--size-64);
  }
}

.componentBigButton {
  position: relative;
  aspect-ratio: 2 / 1;
  background: var(--color-blue-500);
  clip-path: inset(0 0 round 400px);
  transition: background var(--duration-400) var(--ease-out-quad);
  overflow: hidden;

  &.checked {
    background: var(--color-orange-500);
  }

  & > .checkboxSlider {
    height: 100%;
  }

  & > .input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.checkboxSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--color-white);
  background-clip: content-box;
  border: var(--size-12) solid transparent;
  border-radius: 50%;
  transform: translateX(0);
  transition: transform var(--duration-400) var(--ease-out-quad);

  &.checked {
    transform: translateX(100%);
  }

  @media (--viewport-md) {
    border: var(--size-16) solid transparent;
  }

  & > .iconContainer {
    width: 50%;
    height: 50%;
  }
}

.iconContainer {
  transform: rotate(0deg);
  transition: transform var(--duration-400) var(--ease-out-quad);

  &.checked {
    transform: rotate(180deg);
  }
}

.input {
  opacity: none;
  cursor: pointer;
}

.pointsContainer {
  display: grid;
  grid-template-areas: 'content';

  & > * {
    grid-area: content;
  }
}

.componentPointsList {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  color: var(--color-blue-900);

  @media (--viewport-md) {
    gap: var(--size-64);
  }
}

.pointContainer {
  pointer-events: none;
  opacity: 0;
  transform: translateX(-25px);
  transition: var(--duration-400) var(--ease-out-quad);
  transition-delay: 0s;

  &.active {
    pointer-events: auto;
    opacity: 1;
    transform: translateX(0);
    transition-delay: calc(0.3s + 0.15s * var(--index));
  }
}

.componentPoint {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.pointTitle {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
}

.pointValue {
  font-size: var(--font-size-20);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
}
