.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-20);

  & > .navigationLayout {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.componentButtonBase,
.placeholderLink {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-12) var(--size-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-paragraph);
  text-decoration: none;
  transition: background-color var(--duration-150) ease;

  &:hover {
    background-color: var(--color-blue-200);
  }

  @media not (--viewport-md) {
    display: none;
  }

  &:not(:last-child) {
    @media (--viewport-md) {
      border-right: 2px solid var(--color-blue-200);
    }
  }

  &:disabled {
    opacity: 0.35;
    pointer-events: none;
    user-select: none;
  }

  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.componentNavigation {
  display: flex;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: var(--radius-4);
  overflow: hidden;

  @media not (--viewport-md) {
    gap: var(--size-12);
    background-color: transparent;
  }

  & > .buttonPreviousLayout,
  & > .buttonNextLayout {
    @media not (--viewport-md) {
      flex: 1;
    }
  }
}

.componentButtonPrevious,
.componentButtonNext {
  @media not (--viewport-md) {
    background-color: var(--color-white);
    border-radius: var(--radius-4);
    display: flex;
    justify-content: center;
  }
}

.paginationLabel {
  color: var(--color-white);

  @media (--viewport-md) {
    display: none;
  }
}

.active {
  background-color: var(--color-blue-200);
}
