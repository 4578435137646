.component {
  display: flex;
  justify-content: space-between;
  gap: var(--size-32);

  & > .textContainer {
    flex: 2;
    height: max-content;
  }

  & > .lineContainer {
    width: max-content;

    @media (--viewport-md) {
      flex: 1;
    }
  }
}

.lineContainer {
  display: flex;
  justify-content: flex-start;

  & > .lineLayout {
    width: var(--size-4);
    height: 100%;
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: 0;
    }
  }
}
