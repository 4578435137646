.page {
  position: relative;
  z-index: 0;
  background-color: var(--color-blue-500);
  padding-top: var(--size-64);

  @media (--viewport-md) {
    padding-top: var(--size-100);
  }

  & > .featuredProjectsLayout {
    position: relative;
    z-index: -1;
  }
}
