.component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--size-24) 0;
  color: var(--color-blue-900);
  font-size: var(--font-size-20);
  font-size: var(--font-size-18-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-paragraph);
  letter-spacing: var(--letter-spacing-block);

  @media (--viewport-md) {
    justify-content: space-between;
    gap: var(--size-20);
  }

  & > .drawerButtonLayout {
    min-width: fit-content;
  }
}

.componentJobAreaFilter {
  overflow: hidden;
  display: flex;
  gap: var(--size-64);
}

.componentDrawerToggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-12);
  cursor: pointer;

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.componentDrawer {
  position: relative;
  pointer-events: none;
  background: transparent;
  transition: background var(--duration-600) ease;

  &.open {
    pointer-events: auto;
    background: var(--color-blue-900--40);
  }

  & > .drawerContainer {
    position: absolute;
    width: 100%;
    height: 100vh;
    right: 0;

    @media (--viewport-md) {
      width: max-content;
    }
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  padding: 0 var(--size-24);
  overflow-y: scroll;

  @media (--viewport-md) {
    padding-right: var(--size-48);
    padding-left: var(--size-64);
  }

  & > .drawerToggleContainer {
    top: 0;
    margin-bottom: var(--size-64);
  }

  & > .filterList {
    margin-top: var(--size-32);
  }
}

.drawerToggleContainer {
  position: sticky;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: var(--size-24) 0;

  @media (--viewport-md) {
    padding-top: var(--size-48);
    justify-content: flex-start;
  }

  & > .resetFilterLayout {
    @media (--viewport-md) {
      margin-right: var(--size-200);
    }
  }
}

.componentCloseDrawerButton {
  & > .iconLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}

.filterList {
  display: flex;
  flex-direction: column;
}

.componentFilterList {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.checkboxesList {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  color: var(--color-blue-900);
}

.filterListTitle {
  color: var(--color-blue-900);
  font-size: var(--font-size-32);
  font-size: var(--font-size-22-32);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
}

.drawerItemsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
  padding-bottom: var(--size-150);

  @media (--viewport-md) {
    padding-bottom: var(--size-80);
  }
}

.componentFilterGroupBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

.checkboxWithChildren {
  display: flex;
  flex-direction: column;
}

.parentCheckbox {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.collapseButton {
  & > .collapseIconContainer {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.collapseIconContainer {
  transition: transform var(--duration-600) var(--ease-out-expo);

  &.isCollapsed {
    transform: rotate(-180deg);
  }

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.childCheckboxesContainer {
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows var(--duration-600) var(--ease-out-expo);
  overflow: hidden;

  &.isCollapsed {
    pointer-events: auto;
    grid-template-rows: 1fr;
  }

  & > * {
    min-height: 0;
  }
}

.childCheckbox {
  --line-height: 100%;
  --line-align: center;

  display: flex;

  &:last-of-type {
    --line-height: calc(50% + 1px);
    --line-align: flex-start;
  }

  & > .parentChildLine {
    width: var(--size-24);
  }
}

.parentChildLine {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 2px;
    height: var(--line-height);
    align-self: var(--line-align);
    background-color: var(--color-blue-300);
  }

  &::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background-color: var(--color-blue-300);
  }
}
