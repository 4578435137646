.component {
  aspect-ratio: 5 / 7;
  z-index: 0;
  position: relative;
  border-radius: var(--radius-10);
  overflow: hidden;

  & > .textContainer {
    z-index: 2;
    position: absolute;
    bottom: 0;
  }

  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
  }
}

.textContainer {
  padding: var(--size-24) var(--size-32);
}

.quote {
  color: var(--color-white);
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
  hyphens: auto;
}

.imageContainer {
  & > .imageLayout {
    height: 100%;
  }
}
