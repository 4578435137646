.componentJobListFilter {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-12);
  color: var(--color-blue-700);
  background-color: var(--color-white);
  border-radius: var(--radius-10);
  font-size: var(--font-size-16);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-block);

  &:hover {
    background-color: var(--color-blue-200);
  }

  & > .baseLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.select {
  opacity: 0;
  cursor: pointer;
}

.iconLayout {
  min-width: max-content;
}
