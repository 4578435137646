.component {
  z-index: 0;
  position: relative;
  aspect-ratio: 1;
  border-radius: var(--radius-10);
  overflow: hidden;

  &.landscape {
    aspect-ratio: 5 / 3;
  }

  & > .tagContainer {
    z-index: 1;
    position: absolute;
    bottom: 0;
  }
}

.tagContainer {
  padding: var(--size-16);

  @media (--viewport-md) {
    padding: var(--size-24);
  }

  & > .tagLayout {
    width: max-content;
  }
}
