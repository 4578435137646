.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: 0 var(--size-64);
  overflow: hidden;

  & > * {
    width: 100%;
    max-width: var(--container-max-width-xl);
    margin-left: auto;
    margin-right: auto;
  }

  & > .slider {
    overflow: visible;
  }
}

.slider {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .slideContainer {
    height: auto;
  }
}

.slideContainer {
  & > .slideLayout {
    height: 100%;
  }
}

.componentImageSlide {
  --image-opacity: 0;

  opacity: 0.3;
  border: 1px solid var(--color-blue-300);
  border-radius: 400px;
  overflow: hidden;
  transition: var(--duration-400) var(--ease-out-circ);
  transition-property: border, opacity;

  &.isActiveSlide {
    opacity: 1;
    border: 1px solid transparent;
    --image-opacity: 1;
  }
}

.imageContainer {
  opacity: var(--image-opacity);
  transition: opacity var(--duration-400) var(--ease-out-circ);
}

.titlesContainer {
  display: grid;
  grid-template-areas: 'content';

  & > * {
    grid-area: content;
  }
}

.componentProjectTitle {
  --arrow-offset: 0;

  opacity: 0;
  pointer-events: none;
  transition: opacity var(--duration-600) var(--ease-out-circ);
  transition-delay: 0s;

  &.isActiveSlide {
    opacity: 1;
    pointer-events: auto;
    transition-delay: 0.15s;
  }

  &:hover {
    --arrow-offset: var(--size-24);
  }
}

.anchor {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .arrowIconContainer {
    width: var(--size-48);
    height: var(--size-48);
  }
}

.arrowIconContainer {
  transform: translateX(var(--arrow-offset));
  transition: transform var(--duration-250) var(--ease-out-circ);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
