.componentBase {
  background-color: var(--color-blue-500);
  color: var(--color-white);
  padding: var(--size-150) 0 var(--size-100);

  & > .containerLayout {
    min-height: 60vh;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-64);

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-48);
  }

  & > .textContainer {
    flex: 1;

    @media (--viewport-lg) {
      flex: 0.5;
    }

    @media (--viewport-xl) {
      flex: 0.5;
    }
  }

  & > .jobBoardLayout {
    flex: 1;

    @media (--viewport-lg) {
      flex: 0.55;
    }

    @media (--viewport-xl) {
      flex: 0.4;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);

  & > .buttonWrapper {
    width: 100%;
    margin-top: var(--size-20);

    @media (--viewport-sm) {
      width: fit-content;
    }
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--size-16);

  @media (--viewport-lg) {
    gap: var(--size-24);
  }

  & > .buttonLayout {
    width: 100%;

    @media (--viewport-sm) {
      width: max-content;
    }
  }
}
