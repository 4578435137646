.component {
  background-color: var(--color-white);

  &::after {
    content: '';
    display: block;
    height: var(--size-8);
    background-color: var(--color-blue-500);
  }
}

.footerContent {
  display: flex;
  flex-direction: column;
  padding: var(--size-40) 0;
  gap: var(--size-32);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-100);
    align-items: flex-end;
  }

  & > .logoLayout {
    width: var(--size-80);
    height: auto;

    @media (--viewport-md) {
      width: var(--size-150);
      height: auto;
    }
  }
}

.componentLogo {
  color: var(--color-blue-500);
}

.componentNavigation {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  list-style: none;
  white-space: nowrap;

  @media (--viewport-md) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    gap: var(--size-16) var(--size-80);
  }

  & > :nth-child(5) {
    grid-row-start: 3;

    @media (--viewport-sm) {
      grid-row-start: unset;
    }
  }
}

.componentLink,
.componentCookieButton {
  --opacity-icon: 0;
  --translateX-icon: calc(-100% - var(--size-24));

  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-heading);
  color: var(--color-blue-900);

  &:hover {
    --opacity-icon: 1;
    --translateX-icon: calc(-100% - var(--size-4));

    color: var(--color-blue-600);
  }

  & > .iconContainer {
    position: absolute;
    width: var(--size-24);
    height: var(--size-24);
  }
}

.iconContainer {
  display: none;
  pointer-events: none;
  opacity: var(--opacity-icon);
  transform: translateX(var(--translateX-icon));
  transition: opacity var(--duration-150) var(--ease-out-circ), transform var(--duration-150) var(--ease-out-circ);

  @media (--viewport-md) {
    display: block;
  }

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
