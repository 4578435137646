.component {
  --color: var(--color-blue-900);
}

.componentWhite {
  --color: var(--color-white);
}

.componentPageHero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .dot {
    position: absolute;
    width: var(--size-16);
    height: var(--size-16);
    top: calc(-1 * var(--size-4));
  }

  & > .line {
    width: var(--size-4);
  }
}

.componentBase {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > .dot {
    z-index: 1;
    position: absolute;
    width: var(--size-16);
    height: var(--size-16);
    top: calc(-1 * var(--size-4));
  }

  & > .lineContainer {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .destinationDot {
    z-index: 2;
    position: absolute;
    width: var(--size-16);
    height: var(--size-16);
    bottom: 0;
  }
}

.dot {
  border-radius: 50%;
  background-color: var(--color);
  transition: opacity var(--duration-1500) ease;

  &.withEnterAnimation {
    opacity: 0;
    animation: dotEnterAnimation var(--duration-600) ease forwards;
    animation-delay: var(--duration-850);
  }
}

.lineContainer {
  display: flex;
  overflow: hidden;
  transform-origin: top;

  &.withEnterAnimation {
    transform: scaleY(0);
    animation: lineEnterAnimation var(--duration-850) ease forwards;
    animation-delay: var(--duration-1500);
  }

  & > .line {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.line {
  transform: scaleY(0);
  transform-origin: top;
  background-color: var(--color);
}

.destinationDot {
  opacity: 0;
  size: 0;
  border-radius: 50%;
  background-color: var(--color);
  translate: 0 1px;
}

.dotPageHero {
  opacity: 0;
  border-radius: 50%;
  background-color: var(--color);
  transition: opacity var(--duration-1500) ease;
}

@keyframes dotEnterAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes lineEnterAnimation {
  0% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}
