.component {
  position: relative;
  padding: var(--size-125) 0 var(--size-80);
  color: var(--color-white);
  background-color: var(--color-blue-500);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-80);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .infoLayout {
    flex: 2;
  }

  & > .imageLayout {
    flex: 1;
    max-width: 450px;
  }
}

.jobTitle {
  font-family: var(--font-family-base);
  font-size: var(--font-size-100);
  font-size: var(--font-size-50-100);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
}

.componentInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--size-48);
}
