.componentBase {
  background-color: var(--color-blue-500);
  padding-top: var(--size-80);

  @media (--viewport-md) {
    padding-top: var(--size-200);
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-24);
  padding: var(--size-24) 0;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: flex-end;
    gap: var(--size-16);
  }

  & > .applyingTextLayout {
    max-width: 100%;

    @media (--viewport-md) {
      max-width: 80%;
    }
  }
}

.jobTitle {
  color: var(--color-white);
}
