.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: 0 var(--size-24);

  @media (--viewport-md) {
    display: grid;
    grid-template-rows: repeat(auto, 1fr);
    grid-template-columns: repeat(12, 1fr);
    padding: 0;
  }

  @media (--viewport-lg) {
    display: grid;
    gap: var(--size-52);
  }

  @media (--viewport-xl) {
    display: grid;
  }

  & > * {
    width: 100%;
  }

  & > .documentCardLayout {
    @media (--viewport-md) {
      grid-column: span 6;
    }

    @media (--viewport-lg) {
      grid-column: span 6;
    }

    @media (--viewport-xl) {
      grid-column: span 5;
    }
  }

  & > .imageCardLayout {
    @media (--viewport-md) {
      grid-column: span 6;
    }

    @media (--viewport-xl) {
      grid-column: span 4;
    }
  }

  & > .videoCardLayout {
    @media (--viewport-md) {
      grid-column: span 7;
    }

    @media (--viewport-lg) {
      grid-column: span 7;
    }
  }

  & > .eventCardLayout {
    @media (--viewport-md) {
      grid-column: span 5;
    }

    @media (--viewport-lg) {
      grid-column: span 4;
    }
  }

  & > .quoteCardLayout {
    @media (--viewport-md) {
      grid-column: span 5;
    }

    @media (--viewport-xl) {
      grid-column: span 4;
    }
  }
}
