.componentBase,
.componentWithIcon,
.componentWithLogo {
  padding: var(--size-4) var(--size-16);
  color: var(--color-blue-900);
  background-color: var(--color-blue-200);
  font-weight: var(--font-weight-400);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-size: var(--font-size-16-18);
  border-radius: var(--radius-100);
  white-space: nowrap;

  @media (--viewport-md) {
    padding: var(--size-8) var(--size-20);
  }
}

.componentWithIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--size-8);
  white-space: wrap;

  @media (--viewport-md) {
    white-space: nowrap;
  }

  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);

    @media (--viewport-md) {
      width: var(--size-24);
      height: var(--size-24);
    }
  }
}

.componentWithLogo {
  padding: var(--size-8) var(--size-16);

  @media (--viewport-md) {
    padding: var(--size-12) var(--size-20);
  }

  & > .iconLayout {
    height: var(--size-20);
  }
}

.tagWhiteBackground {
  color: var(--color-blue-900);
  background-color: var(--color-white);
}

.tagBlueBackground {
  color: var(--color-white);
  background-color: var(--color-blue-500);
}

.tagDarkBlueBackground {
  color: var(--color-white);
  background-color: var(--color-blue-900);
}

.tagDefaultBackground {
  color: var(--color-blue-900);
  background-color: var(--color-blue-200);
}
