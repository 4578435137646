.component {
  display: flex;
}

.componentForm {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  @media (--viewport-lg) {
    flex-direction: row;
    gap: 250px;
  }

  &.smallGap {
    @media (--viewport-lg) {
      gap: var(--size-52);
    }
  }

  & > .asideText {
    flex: 1;

    @media (--viewport-lg) {
      flex: 3;
    }
  }

  & > .form {
    width: 100%;
    flex: 1;

    @media (--viewport-lg) {
      flex: 7;
    }
  }
}

.asideText {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.componentFormFields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-20);

  & > * {
    width: 100%;
  }

  & > .navigateContainer {
    width: 100%;
    margin-top: var(--size-32);
  }
}

.twoInputFields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-12);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    flex: 1;
    width: 100%;
  }
}

.navigateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    order: 1;
    margin-left: auto;

    &:nth-child(2) {
      order: 0;
      margin-left: unset;
    }
  }
}
