.component {
  display: flex;
}

.componentSection {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  @media (--viewport-lg) {
    flex-direction: row;
    gap: 250px;
  }

  &.smallGap {
    @media (--viewport-lg) {
      gap: var(--size-52);
    }
  }

  & > .sectionText {
    flex: 1;

    @media (--viewport-lg) {
      flex: 3;
    }
  }

  & > .formLayout {
    flex: 1;

    @media (--viewport-lg) {
      flex: 7;
    }
  }
}

.sectionText {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}
