
.component,
.componentLanguageSwitch,
.componentLogin {
  opacity: 0;
  pointer-events: none;
  padding: var(--size-48) 0 var(--size-64);
  color: var(--color-blue-900);
  border-bottom: 2px solid var(--color-blue-900);
  transition: opacity var(--duration-250) ease;

  &.thisSubmenuIsActive {
    opacity: 1;
    pointer-events: auto;
    transition-delay: var(--duration-150);
  }

  &:last-of-type {
    border-bottom: 2px solid transparent;
  }
}

.componentSubmenuHeading {
  display: flex;
  align-items: center;
  gap: var(--size-16);
  color: var(--color-blue-900);

  & > .iconLayout {
    width: var(--size-80);
    height: var(--size-80);

    @media (--viewport-xl) {
      width: var(--size-125);
      height: var(--size-125);
    }
  }
}

.submenuContainer {
  display: grid;
  grid-template-columns: minmax(max-content, calc(var(--offset-submenu) - var(--size-24))) 1fr;
  grid-template-areas: 'heading submenu';
  gap: var(--size-24);
  line-height: var(--line-height-block);

  & > .submenuHeadingLayout {
    grid-area: heading;
  }

  & > .submenu {
    grid-area: submenu;
  }
}

.submenu {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  gap: var(--size-20) var(--size-52);

  &.loginSubmenu {
    grid-template-columns: max-content;
  }
}

.componentSubmenuLink {
  --opacity-icon: 0;
  --translateX-icon: calc(-100% - var(--size-24));

  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-blue-900);

  &:hover {
    --opacity-icon: 1;
    --translateX-icon: calc(-100% - var(--size-4));

    color: var(--color-blue-600);
  }

  & > .arrowIconContainer {
    position: absolute;
    width: var(--size-24);
    height: var(--size-24);
  }

  & > .submenuLink {
    position: static;
  }
}

.submenuLink {
  position: relative;

  & > .submenuLinkLabel {
    position: static;
  }
}

.arrowIconContainer {
  pointer-events: none;
  opacity: var(--opacity-icon);
  transform: translateX(var(--translateX-icon));
  transition: opacity var(--duration-150) var(--ease-out-circ), transform var(--duration-150) var(--ease-out-circ);

  & > .arrowIconLayout {
    width: 100%;
    height: 100%;
  }
}

.submenuLinkLabel {
  --opacity-underline: 0;

  position: relative;
  white-space: nowrap;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);

  &.isActive {
    --opacity-underline: 1;
  }

  &::before {
    opacity: var(--opacity-underline);
    content: attr(data-content);
    position: absolute;
    top: 1px;
    left: 0;
    color: transparent;
    text-decoration: underline;
    text-decoration-color: var(--color-blue-600);
    text-decoration-thickness: 2px;
  }
}
