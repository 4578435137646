.componentBase {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--color-blue-500);
  text-underline-offset: 3px;

  &:hover {
    text-decoration-color: var(--color-blue-700);
  }
}
