.component {
  position: relative;
  overflow: hidden;

  & > .framesOnScrollLayout {
    width: 100vw;
    height: 100lvh;
  }
}

.componentHeroImageComponent {
  object-fit: cover;
}
