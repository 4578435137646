.componentLooping {
  --background-color: var(--color-blue-900);
  --iteration-count: infinite;
}

.componentOnceWhite {
  --background-color: var(--color-white);
  --iteration-count: 1;
}

.componentOnceBlue {
  --background-color: var(--color-blue-900);
  --iteration-count: 1;
}

.componentBase {
  position: relative;

  & > .ring {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.ring {
  display: block;
  border-radius: 50%;
  background-color: var(--background-color);
  transform-origin: center;
  opacity: 0;
  animation: ringAnimation var(--duration-3000) ease-in var(--iteration-count);
}

@keyframes ringAnimation {
  0% {
    opacity: 0;
    scale: 1;
  }

  6% {
    opacity: 0.4;
    scale: 1;
  }

  60% {
    opacity: 0;
    scale: 2;
  }
}
