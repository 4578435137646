.componentBase {
  --transition-delay: var(--duration-600);
  --overlay-opacity: 0;
  --arrow-x-offset: -50px;

  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding: var(--size-16);
  border-radius: var(--radius-10);

  @media (--viewport-md) {
    padding: var(--size-24);
  }

  &:hover {
    --transition-delay: 0s;
    --overlay-opacity: 1;
    --arrow-x-offset: 0;
  }

  & > .imageLayout {
    margin-top: auto;
  }

  & > .textContainer {
    z-index: 1;
    position: static;
  }
}

.componentWhite {
  background-color: var(--color-white);
}

.componentLightBlue {
  aspect-ratio: 5 / 4;
  background-color: var(--color-blue-200);

  @media (--viewport-md) {
    aspect-ratio: 5 / 7;
  }
}

.textContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-24);

  & > .cardHeadingLayout {
    position: static;
  }
}

.componentCardHeading {
  display: -webkit-box;
  position: relative;
  font-size: var(--font-size-40);
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  & > .cardAnchor {
    position: static;
  }
}

.cardAnchor {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.cardDescription {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
}

.componentCardImage {
  position: relative;
  border-radius: var(--radius-4);
  overflow: hidden;

  & > .imageOverlayLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentImageOverlay {
  opacity: var(--overlay-opacity);
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  transition: opacity var(--duration-250) var(--ease-out-quad);

  & > .overlayIconContainer {
    z-index: 2;
    width: 45%;
  }

  & > .backgroundOverlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.overlayIconContainer {
  aspect-ratio: 1;
  transform: translateX(var(--arrow-x-offset));
  transition: transform var(--duration-250) var(--ease-out-quad);
  transition-delay: var(--transition-delay);

  & > .overlayIconLayout {
    width: 100%;
    height: 100%;
  }
}

.backgroundOverlay {
  opacity: 0.6;
  background-color: var(--color-blue-900);
}
