.component {
  background-color: var(--color-white);
}

.componentSearchbar {
  border-bottom: 2px solid var(--color-blue-900);
}

.searchBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-300) 0 var(--size-40);

  & > .searchBarInput {
    width: 80%;
  }
}

.searchBarInput {
  outline: none;
  color: var(--color-blue-900);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-120);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
  letter-spacing: var(--letter-spacing-heading);

  @media (--viewport-lg) {
    line-height: var(--line-height-normal);
  }

  &::placeholder {
    opacity: 1;
    color: var(--color-blue-900);
  }
}

.searchButton {
  & > .iconLayout {
    width: var(--size-64);
    height: var(--size-64);

    @media (--viewport-md) {
      width: var(--size-125);
      height: var(--size-125);
    }
  }
}
