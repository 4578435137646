.component {
  overflow: hidden;
  color: var(--color-blue-900);

  & > * {
    margin-bottom: var(--size-16);

    &:has(+ .headingLayout),
    &:has(+ .subheadingLayout) {
      margin-bottom: var(--size-64);
    }
  }

  & > .headingLayout {
    margin-bottom: var(--size-24);
  }

  & > .subheadingLayout {
    margin-top: var(--size-64);
    margin-bottom: var(--size-20);
  }

  & > .listUnordered {
    margin-left: var(--size-20);
  }

  & > .listOrdered {
    margin-left: var(--size-20);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.paragraph {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  white-space: normal;
}

.listOrdered {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  list-style-type: decimal;
  list-style-position: outside;
}

.listUnordered {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  list-style-type: disc;
  list-style-position: outside;
}

.link {
  text-decoration: underline;
}
