.component {
  & > .rangeFieldLayout {
    width: 90%;
    margin-top: var(--size-16);

    @media (--viewport-md) {
      width: 80%;
    }
  }
}

.componentRangeField {
  --dot-size: var(--size-12);
  --half-dot-size: calc(var(--dot-size) / 2);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'labels'
    'sliders';

  & > .rangeLabelsLayout {
    grid-area: labels;
  }

  & > .rangeSpanLayout,
  & > .rangeTrackLayout,
  & > .rangeInputsLayout {
    grid-area: sliders;
  }

  & > .rangeIndicatorsLayout {
    grid-area: labels;
  }

  & > :not(.rangeInputsLayout) {
    margin-left: var(--half-dot-size);
    margin-right: var(--half-dot-size);
  }

  & > .rangeTrackLayout {
    align-self: center;
  }
}

.componentRangeInputs {
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
    width: 100%;
  }
}

.componentRangeInput {
  pointer-events: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-slider-thumb {
    background-color: var(--color-blue-900);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: var(--size-16);
    width: var(--size-16);
    pointer-events: all;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  &::-moz-range-thumb {
    background-color: var(--color-blue-900);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: var(--size-16);
    width: var(--size-16);
    pointer-events: all;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.componentRangeSpan {
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;

  & > .rangeSpanLineLayout {
    position: relative;
  }
}

.rangeSpanLine,
.componentRangeTrack {
  height: 1px !important;
}

.rangeSpanLine {
  background-color: var(--color-blue-900);
}

.componentRangeTrack {
  background-color: var(--color-blue-200);
}

.componentRangeLabel {
  color: var(--color-blue-900);
  opacity: 0.4;

  &.start {
    transform: translateX(-50%);
  }

  &.end {
    transform: translateX(50%);
  }
}

.componentRangeLabels {
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.componentRangeIndicators {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  user-select: none;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
    position: relative;
    width: fit-content;
  }
}

.componentRangeIndicator {
  transform: translateX(-50%);
  transform-origin: center;
}
