.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-40);
  padding: var(--size-40) var(--size-20) var(--size-20);
  color: var(--color-blue-900);
  background-color: var(--color-blue-200);
  border-radius: var(--radius-20);

  @media (--viewport-md) {
    padding: var(--size-40) var(--size-24);
  }

  @media (--viewport-lg) {
    gap: var(--size-64);
    padding: var(--size-80) var(--size-64);
  }

  @media (--viewport-xl) {
    padding: var(--size-80) var(--size-125);
  }

  & > .benefitsCardsLayout {
    width: 100%;
  }
}

.bannerHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);
  padding: 0 var(--size-20);

  @media (--viewport-md) {
    gap: var(--size-32);
  }
}

.componentBenefitsButton {
  display: flex;
  align-items: center;
  gap: var(--size-16);
  color: var(--color-blue-900);
  font-size: var(--font-size-32);
  font-size: var(--font-size-22-32);
  font-weight: var(--font-weight-400);
  transition: gap var(--duration-250) var(--ease-out-quad);

  &:hover {
    gap: var(--size-24);
  }

  & > .arrowIconLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}

.componentBenefitsCards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-16);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media (--viewport-lg) {
    display: flex;
  }

  @media (--viewport-xl) {
    gap: var(--size-16);
  }

  & > .cardLayout {
    @media (--viewport-lg) {
      width: calc(100% / 4 - var(--size-16));
    }
  }
}

.componentBenefitCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-20);
  background-color: var(--color-white);
  border-radius: var(--radius-10);

  @media (--viewport-md) {
    padding: var(--size-32);
  }

  @media (--viewport-lg) {
    aspect-ratio: 1;
  }

  & > .iconLayout {
    width: var(--size-48);
    height: var(--size-48);

    @media (--viewport-sm) {
      width: var(--size-80);
      height: var(--size-80);
    }
  }
}

.label {
  text-align: center;
  font-size: var(--font-size-20);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-paragraph);
}
