.component {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  flex-wrap: wrap;

  & > * {
    width: fit-content;
  }
}
