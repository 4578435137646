.page {
  background-color: var(--color-blue-500);
}

.componentCardsGrid {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-64) 0;

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-20);
  }
}

.componentCard {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-32);
  padding: var(--size-24);
  background-color: var(--color-white);
  border-radius: var(--radius-10);

  & > .imageContainer {
    min-width: var(--size-150);
    width: var(--size-150);
    height: var(--size-150);
  }

  & > .textContainer {
    position: static;
  }
}

.imageContainer {
  aspect-ratio: 1;
  border-radius: var(--radius-4);
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.textContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  & > .cardAnchor {
    position: static;
  }

  & > .tagLayout {
    width: max-content;
  }
}

.cardAnchor {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}
