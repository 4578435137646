.component {
  pointer-events: auto;
  background: transparent;

  & > .logoLayout {
    width: var(--size-48);
    height: 100%;

    @media (--viewport-md) {
      width: var(--size-64);
    }
  }
}
