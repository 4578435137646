.component {
  pointer-events: none;
  position: sticky;
}

.componentNavigation {
  overflow: hidden !important;
  pointer-events: none;
  background: transparent;
  transition: background var(--duration-1500) ease;

  &.open {
    pointer-events: auto;
    background: var(--color-blue-900--40);
    transition-duration: var(--duration-600);
    transition-delay: var(--duration-250);
    overflow: auto !important;
  }
}

.navigationContainer {
  overflow: visible;
}
