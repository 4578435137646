.component {
  position: sticky;
  padding-top: var(--size-16);
  transform: translateY(calc(-1 * var(--size-80)));
  transition:
    transform var(--duration-400),
    color var(--duration-600);
  transition-timing-function: var(--ease-out-quad);

  &.isVisible {
    transform: translateY(0);
  }

  &.isHidden {
    transform: translateY(calc(-1 * var(--size-80)));
  }

  @media (--viewport-lg) {
    padding: 0;
    background-color: var(--color-blue-600);
    transform: translateY(calc(-1 * var(--size-80)));
  }

  & > .barLayout {
    width: 100%;
  }
}

.componentBar {
  position: relative;
  color: var(--color-white);
  transform: translateY(0);
  transition: transform var(--duration-400) var(--ease-out-quad);
  background-color: var(--color-blue-600);
  border-radius: var(--radius-20);

  &.isVisibleWithHeader {
    transform: translateY(var(--size-64));

    @media (--viewport-lg) {
      transform: translateY(var(--size-80));
    }
  }

  &.isHidden {
    transform: translateY(0);
  }

  @media (--viewport-lg) {
    border-radius: 0;
  }
}

.componentContent {
  position: relative;

  & > .container {
    position: static;
    height: var(--size-64);

    @media (--viewport-lg) {
      height: var(--size-80);
    }
  }
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-32);

  @media (--viewport-md) {
    justify-content: space-between;
  }

  & > .buttonsContainer {
    @media not (--viewport-md) {
      position: static;
    }
  }
}

.title {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);

  @media (--viewport-lg) {
    font-size: var(--font-size-22);
  }
}

.buttonsContainer {
  position: relative;
  display: flex;
  gap: var(--size-32);
  white-space: nowrap;

  &.hasOnlyOneLink {
    & > .buttonLayout {
      @media not (--viewport-md) {
        position: static;
      }
    }
  }

  & > .seperator {
    width: 2px;
    height: var(--size-40);
  }
}

.seperator {
  opacity: 0.1;
  background-color: var(--color-blue-200);

  @media (--viewport-md) {
    display: none;
  }
}
