.componentBase {
  display: flex;
  flex-direction: column;
  padding: var(--size-80) 0;
  background-color: var(--color-blue-500);
  overflow: hidden;
}

.component {
  display: flex;
  justify-content: center;

  @media (--viewport-xl) {
    padding: 0 var(--container-padding);
  }

  & > .vacanciesList {
    width: 100%;

    @media (--viewport-xl) {
      max-width: var(--container-max-width-lg);
    }
  }
}

.vacanciesList {
  @media (--viewport-xl) {
    border-radius: var(--radius-30);
  }
}

.vacancyListContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--size-32);

  & > .vacancyList {
    width: 100%;
  }
}

.vacancyList {
  display: flex;
  flex-direction: column;

  & > .vacancyListItemLayout {
    width: 100%;
  }
}

.componentVacancyListItem {
  --opacity-underline: 0;
  --arrow-x-offset: 0;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--size-24);
  padding: var(--size-32) 0;
  color: var(--color-white);
  border-bottom: 2px solid var(--color-white);

  &:last-of-type {
    border-bottom: 2px solid transparent;
  }

  &.showButton {
    &:last-of-type {
      border-bottom: 2px solid var(--color-white);
    }
  }

  @media (--viewport-md) {
    align-items: center;
  }

  &:hover {
    --opacity-underline: 1;
    --arrow-x-offset: var(--size-16);
  }

  & > .contentContainer {
    flex: 1;
    position: static;
  }

  & > .arrowContainer {
    min-width: var(--size-40);
    width: var(--size-40);

    @media (--viewport-md) {
      min-width: var(--size-48);
      width: var(--size-48);
    }
  }
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  & > .jobAnchor {
    position: static;
  }
}

.jobAnchor {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.cardTitle {
  position: relative;
  font-size: var(--font-size-40);
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
  letter-spacing: var(--letter-spacing-heading);

  &::before {
    opacity: var(--opacity-underline);
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    text-decoration: underline;
    text-decoration-color: var(--color-white);
    text-decoration-thickness: 2px;
    transition: opacity var(--duration-250) ease;
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-12);

  & > .tagLayout {
    width: max-content;
  }
}

.arrowContainer {
  pointer-events: none;
  aspect-ratio: 1;
  transform: translateX(var(--arrow-x-offset));
  transition: transform var(--duration-150) var(--ease-out-circ);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.componentTitleLg {
  padding-bottom: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-40);
  }
}

.componentTitleXl {
  padding-bottom: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-64);
  }
}
