.component {
  @media not (--viewport-lg) {
    display: grid;
    grid-template-columns: 5fr minmax(var(--size-100), 1fr);
    grid-template-areas:
      'heading heading'
      'container line';
    gap: var(--size-40) 0;
    padding: var(--size-100) 0 var(--size-80);
  }

  @media (--viewport-lg) {
    padding: var(--size-150) 0 var(--size-200);
  }

  & > .titleContainer {
    grid-area: heading;
  }

  & > .container {
    @media not (--viewport-lg) {
      grid-area: container;
    }
  }

  & > .lineContainer {
    @media not (--viewport-lg) {
      grid-area: line;
      height: calc(100% - var(--size-100));
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: fit-content(5fr) 1fr;
    grid-template-areas: 'title-image line-intro';
    gap: 0;
  }

  & > .titleAndImageLayout {
    @media (--viewport-lg) {
      grid-area: title-image;
      max-width: 1200px;
    }
  }

  & > .lineAndIntroLayout {
    @media (--viewport-lg) {
      grid-area: line-intro;
    }
  }
}

.componentTitleAndImage {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-lg) {
    align-items: center;
    gap: var(--size-64);
  }

  & > .imageContainer {
    max-width: 700px;
    height: 400px;

    @media (--viewport-lg) {
      width: 100%;
      max-width: unset;
      height: 650px;
    }
  }
}

.titleContainer {
  color: var(--color-white);
  padding: 0 var(--container-padding);
  padding-right: 0;
  text-transform: capitalize;
}

.imageContainer {
  clip-path: inset(0 0 round 0 400px 400px 0);

  & > * {
    height: 100%;
    width: 100%;
  }
}

.componentLineAndIntro {
  display: flex;

  @media (--viewport-lg) {
    justify-content: center;
  }

  & > .lineAndIntroContainer {
    width: 100%;
  }
}

.lineAndIntroContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-48);
  padding: 0 0 0 var(--container-padding);

  @media (--viewport-lg) {
    align-items: center;
    padding: 0 var(--container-padding) 0 0;
  }

  & > .lineContainer {
    flex-grow: 1;
    min-height: calc(100% - var(--size-200));
    margin-top: var(--size-100);
  }

  & > .introContainer {
    flex-grow: 0;

    @media (--viewport-sm) {
      width: 300px;
    }
  }
}

.lineContainer {
  display: flex;
  justify-content: center;

  & > .lineLayout {
    width: var(--size-4);
    height: 100%;
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.introduction {
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  white-space: normal;
}

.heading {
  font-size: var(--font-size-240);
  font-size: var(--font-size-70-240);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-heading);
}

.longHeading {
  font-size: var(--font-size-150);
  font-size: var(--font-size-50-150);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-normal);
}

.titleAbbreviation {
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-40);
  font-weight: var(--font-weight-400);
  line-height: 1;
}
