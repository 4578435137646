.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-125);
  overflow: hidden;
  padding: var(--size-125) 0 0;
}

.container {
  padding: 0 var(--size-48);

  & > .slider {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
  }
}

.slider {
  display: flex;
  cursor: grab;
  overflow: visible !important;

  &:active {
    cursor: grabbing;
  }

  & > .slide {
    height: auto;
  }
}

.slide {
  overflow: visible !important;

  & > .slideLayout {
    height: 100%;
  }
}

.componentSlide {
  & > .documentCardLayout {
    @media (--viewport-sm) {
      width: 325px;
    }

    @media (--viewport-md) {
      width: 450px;
    }
  }

  & > .imageCardLayout {
    @media (--viewport-sm) {
      width: 325px;
    }

    @media (--viewport-md) {
      width: 350px;
    }
  }

  & > .videoCardLayout {
    @media (--viewport-sm) {
      width: 325px;
    }

    @media (--viewport-md) {
      width: 500px;
    }
  }

  & > .eventCardLayout {
    @media (--viewport-sm) {
      width: 260px;
    }

    @media (--viewport-md) {
      width: 340px;
    }
  }

  & > .quoteCardLayout {
    @media (--viewport-sm) {
      width: 280px;
    }

    @media (--viewport-md) {
      width: var(--size-300);
    }
  }
}
