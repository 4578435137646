.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.heading {
  color: var(--color-blue-900);
  font-family: var(--font-family-base);
  font-size: var(--font-size-40);
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-400);
}
