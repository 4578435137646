.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  overflow: visible;

  @media (--viewport-md) {
    padding: 0 var(--size-48);
  }

  & > * {
    width: 100%;
    max-width: var(--container-max-width-xl);
    margin-left: auto;
    margin-right: auto;
  }

  & > .slider {
    overflow: visible;
  }
}

.slider {
  display: flex;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.sliderDisabled {
    flex-direction: column;
    gap: var(--size-8);
    cursor: auto;

    @media (--viewport-md) {
      flex-direction: row;
      gap: var(--size-16);
    }

    @media (--viewport-lg) {
      gap: var(--size-32);
    }

    & > * {
      flex: 1;
    }
  }

  & > .slideContainer {
    height: auto;
  }
}

.slideContainer {
  & > .cardLayout {
    width: 100%;
    height: 100%;
  }
}
