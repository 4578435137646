.component {
  z-index: 0;
  position: relative;
  aspect-ratio: 5 / 7;
  border-radius: var(--radius-10);
  overflow: hidden;

  @media (--viewport-md) {
    aspect-ratio: 16 / 9;
  }

  & > .imageLayout {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
