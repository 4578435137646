.component {
  background-color: var(--color-blue-500);
}

.componentJobsGrid {
  display: flex;
  flex-direction: column;

  & > .paginationLayout {
    margin-bottom: var(--size-64);
    width: 100%;

    @media (--viewport-md) {
      margin-bottom: var(--size-150);
      width: auto;
    }
  }
}

.jobsGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: var(--size-20);
  padding: var(--size-100) 0;

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr min-content;
    gap: var(--size-48);
  }

  @media (--viewport-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > .jobCardLayout {
    @media not (--viewport-md) {
      flex-shrink: 0;
      flex-grow: 1;
    }

    @media (--viewport-md) {
      min-height: var(--min-height-card);
      max-height: min-content;
    }
  }

  & > .noJobsFoundLayout {
    @media (--viewport-md) {
      grid-column-start: span 3;
    }
  }

  & > .jobsLoadingLayout {
    @media (--viewport-md) {
      grid-column-start: span 3;
    }
  }
}

.componentNoJobsFound {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-40) var(--size-32) var(--size-32);
  border-radius: var(--radius-8);
  background-color: var(--color-blue-200);
  animation: revealAnimation var(--duration-250) ease forwards;

  & > .resetFilterLayout {
    width: max-content;
  }
}

.componentJobsLoading {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-40) var(--size-32) var(--size-32);
  border-radius: var(--radius-8);
  background-color: var(--color-blue-200);
  animation: revealAnimation var(--duration-250) ease forwards;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

@keyframes revealAnimation {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
