.component {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  font-family: var(--font-family-alt);

  & > .inputFieldCheckbox {
    width: 20px;
    height: 20px;
  }
}

.label,
.labelCheckbox {
  color: var(--color-blue-900);
  font-weight: var(--font-weight-300);
  user-select: none;
}

.inputFieldCheckbox {
  border: 2px solid var(--color-blue-600);
  border-radius: var(--radius-4);
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    background-image: url('/images/icons/checkmark.svg');
    background-position: center;
    background-repeat: no-repeat;
    color: var(--color-blue-600);
    width: 16px;
    height: 13px;
    opacity: 0;
    transform: translateY(15%);
  }

  &:checked {
    background-color: var(--color-blue-600);

    &::after {
      opacity: 1;
    }
  }
}
