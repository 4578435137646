.component {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1.3fr 1fr;
  align-items: center;
  gap: var(--size-150);

  & > .titlesContainerLayout {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  & > .imagesColumn {
    justify-self: center;
    grid-row: 1;
    grid-column: 2 / 3;
    width: 80%;
    max-width: 550px;
    margin-right: var(--size-24);
  }
}

.titlesContainer {
  & > .titlesColumn {
    width: 50%;
  }
}

.titlesColumn {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
}

.imagesColumn {
  display: grid;
  grid-template-areas: 'content';
  border: 1px solid var(--color-blue-300);
  border-radius: 400px;
  overflow: hidden;
  transition: border var(--duration-400) var(--ease-out-circ);

  &.activeHover {
    border: 1px solid transparent;
  }

  & > * {
    grid-area: content;
  }
}

.componentImage {
  opacity: 0;
  transition: opacity var(--duration-400) var(--ease-out-circ);

  &.isHovering {
    opacity: 1;
  }
}

.componentProjectTitle {
  --size-arrow: var(--size-100);

  position: relative;
  display: flex;
  align-items: center;

  @media (--viewport-lg) {
    --size-arrow: var(--size-125);
  }

  & > .iconContainer {
    position: absolute;
    left: calc(-1 * var(--size-arrow));
  }
}

.iconContainer {
  pointer-events: none;
  display: flex;

  & > .iconLayout {
    width: var(--size-arrow);
  }
}
