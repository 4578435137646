.component {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-rows: 500px auto;
  grid-template-areas:
    'space'
    'content';
  justify-content: flex-start;
  pointer-events: none;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: var(--size-200);
    bottom: 0;
    background: linear-gradient(0deg, var(--color-white) 80%, var(--color-blue-500) 100%);
    background-color: var(--color-white);
  }

  & > .windowLayout {
    grid-row: 1 / 3;
    width: 100%;
    height: 100lvh;
    top: 0;
  }
}

.componentWindow {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: url(/images/icons/shape.svg);
  mask-repeat: no-repeat;
  mask-size: 15%;
  mask-position: center;
  overflow: hidden;

  & > .image {
    width: 100vw;
    max-width: 100%;
    height: 100%;
  }

  & > .buttonLayout {
    position: absolute;
    width: max-content;
    max-width: 90%;
    height: max-content;
    bottom: var(--size-48);
  }
}

.image {
  display: flex;
  object-fit: cover;

  & > .imageLayout {
    height: 100%;
  }
}

.componentButton {
  --transform-icon: 0;

  pointer-events: auto;
  display: flex;
  gap: var(--size-20);
  align-items: center;
  padding: var(--size-20) var(--size-32);
  color: var(--color-white);
  border: 2px solid var(--color-white);
  border-radius: var(--radius-100);

  @media (--viewport-md) {
    gap: var(--size-32);
    padding: var(--size-32) var(--size-48);
  }

  &:hover {
    --transform-icon: var(--size-12);
  }

  & > .iconContainer {
    width: var(--size-50);
    height: var(--size-50);

    @media (--viewport-md) {
      width: var(--size-100);
      height: var(--size-100);
    }
  }
}

.buttonLabel {
  font-size: var(--font-size-24-70);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
  letter-spacing: var(--letter-spacing-heading);
}

.iconContainer {
  transform: translateX(var(--transform-icon));
  transition: transform var(--duration-600) var(--ease-out-expo);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
