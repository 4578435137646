.page {
  display: flex;
  flex-direction: column;
  background-color: var(--color-blue-500);
}

.contentContainer {
  border-radius: var(--size-24) var(--size-24) 0 0;
  background-color: var(--color-white);
  padding-top: var(--size-80);
}
