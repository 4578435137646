.component {
  display: flex;
}

.componentBase {
  display: flex;
  flex-direction: column;

  & > .label {
    margin-bottom: var(--size-8);
  }

  & > .textField,
  .inputFieldTextArea {
    width: 100%;
  }
}

.textField {
  display: flex;
  flex-direction: column;

  & > .label {
    margin-bottom: var(--size-8);
  }
}

.textAreaField {
  & > .inputFieldTextArea {
    min-height: var(--size-200);
  }
}

.inputTextField,
.inputDropdown,
.inputFieldTextArea,
.inputFieldNumber {
  border-radius: var(--radius-8);
  border: 2px solid transparent;
  padding: var(--size-12) var(--size-8);
  background-color: var(--color-blue-200);

  &:focus {
    outline: none;
    border: 2px solid var(--color-blue-600);
  }
}

.errorField {
  border: 2px solid var(--color-red-500);
}

.radioGroupOptions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.radioItem {
  background-color: var(--color-blue-200);
  padding: var(--size-12) var(--size-24);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-4);
  gap: var(--size-12);

  & > .inputRadio {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.inputRadio {
  border-radius: 50%;
  border: 2px solid var(--color-blue-900);
  background: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: '';
    opacity: 0;
    display: block;
    width: calc(var(--size-20) / 2);
    height: calc(var(--size-20) / 2);
    border-radius: 50%;
    background: var(--color-blue-900);
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }
}

.label {
  color: var(--color-blue-900);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
}

.formValidationMessage {
  font-size: var(--font-size-14);
  color: var(--color-red-500);
  display: flex;
  align-items: center;
  gap: var(--size-4);
  padding: var(--size-12) 0;

  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.textAreaHeader {
  & > .textAreaDescription {
    margin-top: var(--size-8);
  }
}

.textAreaDescription {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  opacity: 0.4;
}

.fileField {
  z-index: 0;
  position: relative;

  & > .button {
    width: 100%;
  }

  & > .formValidationLayout {
    z-index: -1;
    margin-top: calc(-1 * var(--size-8));
  }
}

.buttonContainer {
  z-index: 0;
  position: relative;

  & > .fileInput {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & > .button {
    position: relative;
    z-index: 0;
    width: 100%;
  }

  & > .formValidationLayout {
    z-index: -1;
    margin-top: calc(-1 * var(--size-8));
  }
}

.fileInput {
  opacity: 0;
  cursor: pointer;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-12) var(--size-24);
  border-radius: var(--radius-10);
  font-size: var(--font-size-16);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing);
  color: var(--color-blue-900);
  background-color: var(--color-blue-300);
  border: 1px solid var(--color-blue-300);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-blue-700);
    border: 1px solid var(--color-blue-700);
  }

  &:disabled {
    opacity: 0.7;
  }

  & > .icon {
    width: var(--size-20);
    height: var(--size-20);
  }
}

/* stylelint-disable-next-line csstools/use-nesting */
/* stylelint-disable-next-line kaliber/selector-policy */
.fileInput:hover ~ .button {
  color: var(--color-white);
  background-color: var(--color-blue-700);
  border: 1px solid var(--color-blue-700);
}

.selectField {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-12);
  padding: var(--size-12) var(--size-24);
  background-color: var(--color-blue-200);
  border-radius: var(--radius-10);
  font-size: var(--font-size-18);
  font-family: var(--font-family-base);
  letter-spacing: var(--letter-spacing-block);

  @media (--viewport-sm) {
    padding: var(--size-20) var(--size-12);
  }

  &:hover {
    background-color: var(--color-blue-300);
  }

  & > .baseLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & > .iconContainer {
    min-width: max-content;
  }
}

.checkbox {
  display: flex;
  align-items: flex-start;
  gap: var(--size-8);
  font-family: var(--font-family-alt);
  padding-bottom: var(--size-8);

  & > .inputFieldCheckbox {
    width: var(--size-20);
    height: var(--size-20);
    margin-top: var(--size-8);
  }
}

.checkboxHasMultipleLines {
  gap: var(--size-32);
}

.label,
.labelCheckbox {
  color: var(--color-blue-900);
  font-weight: var(--font-weight-300);
  user-select: none;
}

.inputFieldCheckbox {
  border: 2px solid var(--color-blue-600);
  border-radius: var(--radius-4);
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    background-image: url('/images/icons/checkmark.svg');
    background-position: center;
    background-repeat: no-repeat;
    color: var(--color-blue-600);
    width: var(--size-16);
    height: var(--size-12);
    opacity: 0;
    transform: translateY(15%);
  }

  &:checked {
    background-color: var(--color-blue-600);

    &::after {
      opacity: 1;
    }
  }
}
