.app {
  z-index: 0;
  position: relative;
  background-color: var(--color-white);

  & > .heroLayout {
    z-index: 3;
  }

  & > .applyNowHeaderLayout {
    z-index: 2;
    width: 100%;
    height: var(--size-80);
    top: 0;
  }

  & > .wrapper {
    z-index: 1;
  }
}

.wrapper {
  z-index: 0;
  position: relative;
}
