.component {
  z-index: 0;
  position: relative;
  contain: paint;
  content-visibility: visible;
  contain-intrinsic-size: 1px 100dvh;

  & > .frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.frame {
  object-fit: cover;
  transform: scaleX(0);
  user-select: none;
  pointer-events: none;

  &[data-visible='true'] {
    transform: scaleX(1);
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentAVIF,
.componentWebP,
.componentImage {
  object-fit: cover;
}
