.component {
  z-index: 0;
  position: relative;

  & > .playButtonLayout,
  & > .imageLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .playButtonLayout {
    z-index: 2;
  }

  & > .imageLayout {
    z-index: 1;
  }

  & > .playerContainer {
    z-index: 0;
    width: 100%;
  }
}

.playerContainer {
  position: relative;
  aspect-ratio: 5 / 7;

  @media (--viewport-md) {
    aspect-ratio: 16 / 9;
  }

  &.retainLandscape {
    aspect-ratio: 16 / 9;
  }

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.player {
  & > * {
    /* stylelint-disable-next-line kaliber/layout-related-properties */
    object-fit: cover;
  }
}
