.component {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-12);

  @media (--viewport-md) {
    gap: var(--size-16);
  }
}

.componentSliderButton {
  --translate: 0;

  color: var(--color-blue-900);
  transition: var(--duration-150) var(--ease-out-quad);
  transition-property: opacity;

  &:first-of-type {
    transform: rotate(180deg);
  }

  &:active {
    --translate: var(--size-12);
  }

  &:disabled {
    opacity: 0.3;

    &:active {
      --translate: 0;
    }
  }

  & > .buttonIconContainer {
    width: var(--size-48);
    height: var(--size-48);

    @media (--viewport-md) {
      width: var(--size-64);
      height: var(--size-64);
    }
  }
}

.buttonIconContainer {
  display: block;
  translate: var(--translate);
  transition: var(--duration-150) var(--ease-out-quad);
  transition-property: translate;

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
