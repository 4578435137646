.component {
  border-bottom: 2px solid transparent;
  visibility: hidden;
  white-space: nowrap;

  &.checked {
    border-bottom: 2px solid var(--color-blue-600);

    &:hover {
      border-bottom: 2px solid var(--color-blue-600);
    }

    &:active {
      border-bottom: 2px solid transparent;
      color: var(--color-blue-900);
    }
  }

  &.isVisible {
    visibility: visible;
  }

  &:hover {
    cursor: pointer;
    color: var(--color-blue-600);
    border-bottom: 2px solid var(--color-blue-600);
  }
}

.filterLabel {
  user-select: none;
}
