.component {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-64);
  padding-bottom: var(--size-100);
  color: var(--color-white);
  background-color: var(--color-blue-900);

  &::before {
    content: "";
    position: absolute;
    display: flex;
    width: 100%;
    top: -1000px;
    left: 0;
    height: 1000px;
    background: linear-gradient(
      0deg,
      var(--color-blue-900) 80%,
      var(--color-blue-500) 100%
    );
    background-color: var(--color-blue-900);
  }
}

.headingContainer {
  display: grid;
  grid-template-columns: var(--size-48) auto;
  grid-template-rows: var(--size-150) auto;

  @media (--viewport-xl) {
    transform: translateX(calc(-1 * var(--size-48)));
  }

  & > .line {
    grid-column: 1;
    grid-row: 1 / 3;
    width: var(--size-4);
    height: calc(100% - var(--size-100));

    @media (--viewport-sm) {
      height: calc(100% - var(--size-40));
    }
  }

  & > .headingLayout {
    grid-column: 2;
    grid-row: 2;
  }
}

.line {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-white);
  transform: translateX(var(--size-12));

  @media (--viewport-md) {
    transform: none;
  }

  &::after {
    content: '';
    display: block;
    width: var(--size-16);
    height: var(--size-16);
    background-color: var(--color-white);
    border-radius: 50%;
    transform: translateY(1px);
  }
}
