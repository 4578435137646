.componentBorder {
  user-select: none;
  pointer-events: none;
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "..."
    "content";

  & > .borderRadius {
    grid-area: content;
    align-self: flex-end;
    height: var(--size-48);
    bottom: 0;
  }
}

.borderRadius {
  position: sticky;
  background-color: var(--color-white);
  border-top-left-radius: var(--radius-30);
  border-top-right-radius: var(--radius-30);
  transform-origin: bottom center;
}

.componentContent {
  background-color: var(--color-white);
}
