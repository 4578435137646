.componentBase {
  display: flex;
  justify-content: center;
  padding: 0 var(--container-padding);
  overflow: hidden;

  & > .container {
    width: 100%;
    max-width: var(--container-max-width-xl);
  }
}

.componentBlue {
  --color: var(--color-white);

  background-color: var(--color-blue-500);
}

.componentLightBlue {
  --color: var(--color-blue-900);

  background-color: var(--color-blue-200);
}

.componentDarkBlue {
  --color: var(--color-white);

  background-color: var(--color-blue-900);
}

.container {
  & > .wrapper {
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: var(--size-100);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: 1.5fr var(--size-200) var(--size-32) 1fr;
    grid-template-rows: 1fr auto;
    align-items: flex-end;
    padding-top: var(--size-125);
  }

  & > .textLayout {
    height: max-content;

    @media (--viewport-lg) {
      grid-column: 1 / 3;
      grid-row: 2;
    }
  }

  & > .imagesLayout {
    width: 100%;
    max-width: 400px;
    height: max-content;

    @media (--viewport-lg) {
      grid-column: 2 / 5;
      grid-row: 1 / 3;
      justify-self: end;
      align-self: end;
      max-width: 500px;
    }

    @media (--viewport-xl) {
      max-width: 600px;
    }
  }
}

.componentImages {
  display: flex;
  gap: var(--size-24);
  padding-bottom: var(--size-32);

  @media (--viewport-lg) {
    flex-direction: row-reverse;
  }

  @media (--viewport-xl) {
    gap: var(--size-32);
    padding-bottom: var(--size-64);
  }

  & > .imageContainer {
    &:nth-of-type(1) {
      flex: 2;
    }

    &:nth-of-type(2) {
      flex: 1;
    }
  }
}

.imageContainer {
  aspect-ratio: 2 / 3;
}

.componentText {
  display: flex;
  align-items: flex-end;
  gap: var(--size-24);

  @media (--viewport-lg) {
    gap: var(--size-64);
  }
}

.textContentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding-bottom: var(--size-32);

  @media (--viewport-xl) {
    padding-bottom: var(--size-64);
  }

  & > .headingContainer {
    max-width: 600px;

    @media (--viewport-lg) {
      max-width: unset;
    }
  }
}

.headingContainer {
  color: var(--color);
}
