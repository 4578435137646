.componentBase {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'text';

  & > .text {
    grid-area: text;
  }
}

.component {
  @media (--viewport-md) {
    grid-template-columns: 1.5fr 2fr;
    grid-template-areas: '... text';
  }
}

.componentCentered {
  text-align: center;
}

.paragraph {
  color: var(--color-blue-900);
  font-family: var(--font-family-base);
  font-size: var(--font-size-40);
  font-size: var(--font-size-24-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-paragraph);
  letter-spacing: var(--letter-spacing-block);
}
