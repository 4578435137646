.component {
  --image-height: 420px;

  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-64) 0;
  background-color: var(--color-white);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    padding: var(--container-padding);
  }

  & > .jobAreaSlider {
    height: auto;

    @media (--viewport-md) {
      height: 900px;
      width: 50%;
    }
  }

  & > .jobAreaNavLayout {
    width: 100%;

    @media (--viewport-md) {
      position: relative;
      width: var(--size-64);
      margin-top: 0;
      top: unset;
      left: unset;
    }
  }

  & > .jobAreaSlideContentLayout {
    width: 100%;

    @media (--viewport-md) {
      margin-left: var(--size-48);
    }
  }
}

.componentJobAreaSlide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: opacity var(--duration-250) ease-out;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .jobAreaSlideImage {
    width: 80%;
    height: var(--image-height);
    margin-bottom: var(--size-64);

    @media (--viewport-sm) {
      width: var(--size-300);
    }

    @media (--viewport-md) {
      --image-height: 687px;

      width: 505px;
      margin-bottom: unset;
    }
  }
}

.jobAreaSlideImage {
  --window-opacity: 1;

  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 400px;
  border: 2px solid transparent;
}

.nextSlide {
  --window-opacity: 0;

  border: 2px solid var(--color-blue-300);
}

.prevSlide {
  --window-opacity: 0;

  border: 2px solid var(--color-blue-300);

  @media (--viewport-md) {
    border: unset;
  }
}

.jobAreaSlider {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    width: 100%;
    height: var(--size-48);
    background: var(--color-white);
    background: linear-gradient(180deg, var(--color-white) 0%, transparent 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @media not (--viewport-md) {
      display: none;
    }
  }

  & > .jobAreaSlideLayout {
    @media not (--viewport-md) {
      height: 100% !important;
    }

    @media (--viewport-md) {
      min-width: unset !important;
    }
  }
}

.componentJobAreaSlideContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-24);
  opacity: 1;
  text-align: center;
  padding: 0 var(--size-24);

  @media (--viewport-md) {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: unset;
  }
}

.componentNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--size-12);
  padding-bottom: var(--size-32);

  @media (--viewport-md) {
    flex-direction: column;
  }

  & > .buttonLayout {
    width: var(--size-24);
    height: var(--size-4);
  }

  & > .jobAreaSliderActiveButton {
    width: var(--size-32);
  }
}

.componentButton {
  background-color: var(--color-blue-300);
  border-radius: var(--radius-38);
  will-change: width;
  transition: width var(--duration-250) ease;
}

.jobAreaSliderActiveButton {
  background-color: var(--color-blue-600);
}

.jobAreaWindowContainer {
  opacity: var(--window-opacity);
  transition: opacity var(--duration-250) ease-out;

  & > .windowLayout {
    height: var(--image-height);
    width: max-content;
    margin: 0 auto;
  }
}

.jobAreaSlideContentTags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-12);
  opacity: 0;
  animation: fadeIn var(--duration-600) ease-out forwards;
  animation-delay: var(--duration-250);

  @media not (--viewport-md) {
    display: none;
  }
}

.jobAreaContentText {
  font-size: var(--font-size-20);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
}

.jobAreaHeading {
  font-size: var(--font-size-100);
  font-size: var(--font-size-40-100);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
}

.slideTextAndButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-48);
  opacity: 0;
  will-change: opacity, transform;
  animation: fadeIn var(--duration-600) ease-out forwards;
  animation-delay: var(--duration-150);

  @media (--viewport-md) {
    align-items: flex-start;
    animation-delay: var(--duration-400);
  }
}

.headingReveal {
  opacity: 0;
  will-change: opacity, transform;
  animation: fadeIn var(--duration-600) ease-out forwards;
  font-size: var(--font-size-32);
}

@keyframes fadeIn {
  0% {
    transform: translateY(var(--size-20));
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
