.component {
  padding-top: var(--size-20);
}

.componentHero {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-80);
  }

  & > * {
    flex: 1;
  }
}

.introduction {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  color: var(--color-white);
  white-space: normal;
}

.titleAbbreviation {
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-40);
  font-weight: var(--font-weight-400);
  line-height: 1;
}

.headingAndIntroContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media not (--viewport-md) {
    gap: var(--size-52);
  }

  & > .lineAndIntroLayout {
    flex: 1;
  }
}

.heading {
  font-family: var(--font-family-base);
  font-size: var(--font-size-140);
  font-size: var(--font-size-70-140);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
}

.headingLongTitle {
  font-size: var(--font-size-120);
  font-size: var(--font-size-70-120);
}

.componentLineAndIntro {
  --color: var(--color-white);

  position: relative;
  display: flex;
  align-items: flex-end;
  gap: var(--size-80);

  & > .lineContainer {
    position: absolute;
    height: calc(100% + var(--size-64));
    top: 0;
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding: 0 0 var(--size-32);
  color: var(--color-white);

  @media (--viewport-md) {
    padding: 0 0
      var(--size-64);
  }

  @media (--viewport-lg) {
    padding-right: clamp(var(--size-80), 20vw, var(--size-270));
  }
}

.headingImages {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-20);
  padding-bottom: var(--size-32);

  & > * {
    flex: 1;
  }
}
