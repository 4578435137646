.component {
  display: flex;
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-32);
  }

  & > .leftColumn,
  & > .rightColumn {
    flex: 1;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-32);
  }

  & > .mediaWindowLayout {
    flex: 1;
    width: 100%;
    height: 100%;

    &:nth-of-type(1) {
      width: 50%;
    }
  }
}

.rightColumn {
  & > .mediaWindowLayout {
    width: 100%;
    height: 100%;
  }
}
