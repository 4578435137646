.page {
  position: relative;

  @media not (--viewport-md) {
    padding: 0 var(--size-20);
  }

  & > .lineContainer {
    position: absolute;
    width: var(--size-4);
    left: var(--size-24);
    top: 350px;
    height: var(--size-200);

    @media (--viewport-md) {
      left: var(--size-150);
      top: unset;
      height: var(--size-300);
    }
  }

  & > .contentCollectionLayout {
    margin-top: var(--size-100);
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (--viewport-md) {
      width: 80%;
    }

    @media (--viewport-lg) {
      width: 60%;
    }
  }
}

.lineContainer {
  & > .lineLayout {
    height: 100%;
  }
}
