.component {
  position: relative;
  aspect-ratio: var(--aspect-ratio);
  border-radius: 400px;
  overflow: hidden;
  contain: layout;

  @supports (contain: style layout paint) {
    contain: style layout paint;
  }

  & > .imageWrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.imageWrap {
  position: relative;
  will-change: transform;

  & > .imageLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
