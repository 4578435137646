.component {
  aspect-ratio: 7 / 8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-80);
  padding: var(--size-24);
  background-color: var(--color-blue-500);
  border-radius: var(--radius-10);
}

.iconContainer {
  padding: var(--size-8);
  color: var(--color-blue-900);

  & > .iconLayout {
    width: var(--size-100);
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  & > .tagLayout {
    width: max-content;
  }
}

.title {
  position: relative;
  color: var(--color-white);
  font-size: var(--font-size-40);
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
}
