.component {
  padding-top: var(--size-16);
  transform: translateY(0);
  transition: transform var(--duration-400) var(--ease-out-quad);

  &.isHidden {
    transform: translateY(calc(-1 * var(--size-80)));
  }

  & > .navbar {
    width: 100%;
    height: var(--size-64);
  }
}

.navbar {
  z-index: 0;
  position: relative;
  background-color: var(--color-blue-200);
  border-radius: var(--radius-20);
  box-shadow: 0 20px 20px rgba(0, 91, 130, 0.12);

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-blue-200);
    border-radius: var(--radius-20);
  }

  & > .menuLayout {
    z-index: -2;
    position: absolute;
    width: 100%;
    max-height: calc(100vh - var(--size-80));
    max-height: calc(100svh - var(--size-80));
    margin-top: calc(-1 * var(--size-20));
  }
}

.wrapper {
  & > .container {
    height: var(--size-64);
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-12) 0 var(--size-16);

  & > .hamburgerLayout {
    height: var(--size-16);
  }
}

.logoContainer {
  color: var(--color-blue-500);
}

.componentHamburger {
  pointer-events: auto;
  display: flex;
  align-items: center;
  gap: var(--size-12);

  & > .hamburger {
    width: var(--size-16);
    height: 2px;
    right: 0;
  }
}

.hamburger {
  position: relative;
  background-color: var(--color-blue-900);
  transition: var(--duration-400) var(--ease-in-out-circ);
  transform: translateY(1px);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: var(--size-16);
    height: 2px;
    left: 0;
    background-color: var(--color-blue-900);
    transition: var(--duration-400) var(--ease-in-out-circ);
  }

  &::before {
    transform: translateY(-6px);
  }

  &::after {
    transform: translateY(6px);
  }

  &.isActive {
    background-color: transparent;
    transition: var(--duration-250) var(--ease-in-out-circ);

    &::before {
      transform: translateY(0);
      rotate: -45deg;
    }

    &::after {
      transform: translateY(0);
      rotate: 45deg;
    }
  }
}

.hamburgerLabel {
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  color: var(--color-blue-900);
}

.componentMenu {
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  background-color: var(--color-blue-200);
  border-radius: 0 0 var(--radius-20) var(--radius-20);
  transition: grid-template-rows var(--duration-600) var(--ease-out-expo);
  overflow: hidden;

  &.isActive {
    pointer-events: auto;
    grid-template-rows: 1fr;
  }

  & > * {
    min-height: 0;
  }
}

.menuContainer {
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  transition: opacity var(--duration-400) var(--ease-in-out-circ);

  &.isActive {
    opacity: 1;
  }
}

.menuItems {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding: var(--size-80) var(--size-32) var(--size-48);
}

.componentMenuItemLink {
  display: flex;
}

.componentMenuItemLabel {
  padding: var(--size-8) 0 0;
  color: var(--color-blue-900);
  font-size: var(--font-size-38);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-paragraph);
  text-align: left;
}

.componentMenuItemWithDropdown {
  z-index: 0;
  position: relative;

  & > .dropdownToggleLayout {
    width: 100%;
  }

  & > .dropdownBackground {
    z-index: -1;
    position: absolute;
    width: calc(100% + var(--size-32));
    height: 100%;
    left: calc(-1 * var(--size-16));
    top: 0;
  }
}

.dropdownBackground {
  opacity: 0;
  border-radius: var(--radius-10);
  background-color: var(--color-blue-300);
  transition: opacity var(--duration-400) var(--ease-out-expo);

  &.thisSubmenuIsActive {
    opacity: 1;
  }
}

.componentDropdownToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-blue-900);
  cursor: pointer;

  & > .iconContainer {
    width: var(--size-32);
    height: var(--size-24);
  }
}

.iconContainer {
  transform: rotate(0);
  transition: transform var(--duration-600) var(--ease-out-expo);

  &.thisSubmenuIsActive {
    transform: rotate(-180deg);
  }

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.componentDropdown {
  opacity: 0;
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  padding: 0;
  transition: var(--duration-600) var(--ease-out-expo);
  transition-property: opacity, grid-template-rows, padding;
  overflow: hidden;

  &.isActive {
    opacity: 1;
    pointer-events: auto;
    grid-template-rows: 1fr;
    padding: var(--size-20) 0 var(--size-24);
  }

  & > * {
    min-height: 0;
  }
}

.submenus {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.submenuLabel {
  font-family: var(--font-family-base);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  color: var(--color-blue-900);
}

.componentSubmenu {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.submenu {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.componentSubmenuLink {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  color: var(--color-blue-900);
}

.componentLoginLink {
  padding: 0 var(--size-32);
}

.loginLink {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
}

.languageAndLoginContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding-top: var(--size-24);
  background-color: var(--color-blue-200);
}

.componentLanguageSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-32);
  padding: 0 var(--size-32) var(--size-32);
}

.languageSwitchLabel {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  color: var(--color-blue-900);
}

.languageSwitchLinksContainer {
  display: flex;
}

.componentLanguageSwitchLink {
  position: relative;
  padding: var(--size-8) var(--size-12);
  border-radius: var(--radius-4);

  &.isActive {
    background-color: var(--color-blue-300);
    font-weight: var(--font-weight-400);
  }

  & > .languageSwitchLink {
    position: static;
  }
}
