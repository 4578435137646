.component {
  display: flex;
  justify-content: center;

  & > .container {
    width: max-content;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--size-24);
  padding: var(--size-200) 0 var(--size-80);
  color: var(--color-blue-900);

  @media (--viewport-lg) {
    flex-direction: column-reverse;
  }

  & > .introContainer {
    max-width: 250px;

    @media (--viewport-lg) {
      max-width: 360px;
    }
  }
}

.headingContainer {
  white-space: nowrap;
}

.introduction {
  color: var(--color-blue-900);
  font-size: var(--font-size-18);
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
  opacity: 0;
  animation: fadeIn var(--duration-600) ease forwards;
  animation-delay: var(--duration-400);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(var(--size-12));
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
