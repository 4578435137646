.component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--size-20);

  &:has(.notAvailableText) {
    align-items: center;
  }

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-24);
  }

  @media (--viewport-xl) {
    align-items: flex-end;
  }

  & > .ctaLayout {
    width: 100%;

    @media (--viewport-sm) {
      width: max-content;
    }
  }
}

.heading {
  font-size: var(--font-size-150);
  font-size: var(--font-size-70-150);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
}

.componentCTA {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
  font-size: var(--font-size-20);
  white-space: nowrap;

  & > .buttonLayout {
    width: 100%;

    @media (--viewport-sm) {
      width: max-content;
    }
  }
}

.text {
  padding-bottom: var(--size-16);
}

.notAvailableText {
  white-space: wrap;
}
