.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-48);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: 1fr var(--size-150) 2fr;
  }

  & > .textContainer {
    @media (--viewport-lg) {
      grid-column: 1 / 3;
      max-width: 500px;
    }
  }

  & > .imagesContainer {
    width: 100%;

    @media not (--viewport-lg) {
      max-width: 500px;
    }

    @media (--viewport-lg) {
      grid-column: 2 / 4;
      margin-top: -25%;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .buttonLayout {
    @media (--viewport-sm) {
      width: max-content;
    }
  }
}
