.component {
  pointer-events: none;
}

.timelineContainer {
  position: relative;
  display: grid;
  grid-template-columns: var(--size-32) 1fr;
  grid-template-rows: var(--size-150) 1fr var(--size-150);
  grid-template-areas:
    'line ...'
    'line timeline'
    'line ...';

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: auto;
    grid-template-areas:
      '...'
      'timeline'
      '...';
  }

  & > .lineLayout {
    grid-area: line;
    width: var(--size-4);
    height: 100%;

    @media (--viewport-lg) {
      position: absolute;
      left: 50%;
      margin-left: -2px;
    }
  }

  & > .milestonesList {
    grid-area: timeline;
  }
}

.milestonesList {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
}

.componentMilestone {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-16);

  @media (--viewport-lg) {
    flex-direction: row;
    gap: var(--size-48);
  }

  &:nth-of-type(even) {
    @media (--viewport-lg) {
      flex-direction: row-reverse;
    }
  }

  & > .milestoneInfoLayout {
    max-width: 400px;

    @media (--viewport-lg) {
      width: 400px;
      align-self: center;
    }
  }
}

.imageContainer {
  & > .imageLayout {
    max-width: 400px;

    @media (--viewport-lg) {
      width: 400px;
    }
  }
}

.componentMilestoneInfo {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .tagLayout {
    width: max-content;
  }
}

.milestoneLabel {
  color: var(--color-blue-900);
  font-size: var(--font-size-40);
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
}

.milestoneDescription {
  font-family: var(--font-family-paragraph);
  color: var(--color-blue-900);
  font-weight: var(--font-weight-300);
}

.componentLine {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-blue-100);

  &::before {
    z-index: 1;
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: var(--size-16);
    height: var(--size-16);
    background-color: var(--color-blue-900);
    border-radius: 50%;
    translate: 0 -1px;
  }

  & > .progressContainer {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .milestoneMarkersContainer {
    width: 100%;
    height: calc(100% - var(--size-150) * 2);
    margin-top: var(--size-150);
  }
}

.progressContainer {
  display: flex;
  overflow: hidden;

  & > .progressBar {
    width: 100%;
    height: 0;
    max-height: 100%;
  }
}

.progressBar {
  background-color: var(--color-blue-900);
}

.milestoneMarkersContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-80);

  & > .milestoneMarkerLayout {
    position: static;
    flex: 1;
  }
}

.componentMilestoneMarker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media not (--viewport-lg) {
    transform: translateY(-175px);
  }

  @media (--viewport-lg) {
    align-items: center;
  }

  & > .markerDot {
    position: static;
    min-width: var(--size-20);
    height: var(--size-20);
  }
}

.markerDot {
  position: relative;
  display: block;
  background-color: var(--color-blue-100);
  border-radius: 50%;

  & > .activeMarkerDot {
    width: 100%;
    height: 100%;
  }
}

.activeMarkerDot {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-900);
  border-radius: 50%;

  & > .animatedRadarLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
