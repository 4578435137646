.page {
  z-index: 0;
  position: relative;
  background-color: var(--color-blue-500);

  & > .heroLayout {
    z-index: 0;
    min-height: 100vh;
  }

  & > .campaignBannerLayout {
    z-index: 1;
    width: 100%;
    height: 150lvh;
    margin-top: -300px;
  }

  & > .featuredDocumentsLayout {
    z-index: -1;
  }

  & > .jobAreaSliderLayout {
    max-width: var(--container-max-width-xl);
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
}
