.componentBase {
  position: relative;
  background-color: var(--color-blue-900);
  padding: var(--size-150) 0 var(--size-100);
  color: var(--color-white);

  @media (--viewport-lg) {
    padding: var(--size-200) 0 var(--size-175);
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-64);

  @media (--viewport-lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--size-100);
  }

  & > .infoLayout {
    flex: 1;
    max-width: 650px;
    align-self: flex-start;
  }

  & > .buttonsLayout {
    flex: 1;
    width: 100%;
    max-width: 450px;

    @media (--viewport-lg) {
      min-width: 400px;
    }
  }
}

.componentInfo {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  @media (--viewport-lg) {
    gap: var(--size-40);
  }
}

.infoText {
  font-size: var(--font-size-20);
  font-size: var(--font-size-18-20);

  & > .idNumber {
    margin-top: var(--size-20);
  }
}

.idNumber {
  display: block;
}

.boldInfoText {
  font-weight: var(--font-weight-400);
}

.componentButtonsGridBase,
.componentButtonsGridRecruiter,
.componentButtonsGridCustomContact {
  display: flex;
  gap: var(--size-20);
  flex-wrap: wrap;

  & > .buttonLayout {
    width: calc(50% - var(--size-20));
    margin-left: unset;
  }

  &:has(.buttonLayout:nth-child(2)) {
    & > .buttonLayout:nth-child(1) {
      margin-left: var(--size-20);

      @media (--viewport-md) {
        margin-left: var(--size-100);
      }
    }
  }

  &:has(.buttonLayout:nth-child(3)) {
    justify-content: center;

    & > .buttonLayout:nth-child(1) {
      margin-left: unset;
    }
  }

  &:has(.buttonLayout:nth-child(4)) {
    & > .buttonLayout:nth-child(3) {
      margin-left: var(--size-20);

      @media (--viewport-md) {
        margin-left: calc(-1 * var(--size-48));
      }
    }
  }
}

.componentButton {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--size-16);
  background-color: var(--color-blue-600);
  border-radius: var(--radius-10);
  transition: transform var(--duration-250) ease;

  @media (--viewport-md) {
    padding: var(--size-24);
  }

  &:hover {
    transform: scale(1.025);
  }

  & > .iconLayout {
    width: var(--size-64);
    height: var(--size-64);

    @media (--viewport-md) {
      width: var(--size-80);
      height: var(--size-80);
    }
  }
}

.buttonLabel {
  font-size: var(--font-size-18);
}
