.component {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;

  & > .letterLayout {
    height: var(--size-28);
    width: var(--size-20);

    @media (--viewport-md) {
      height: var(--size-36);
      width: var(--size-28);
    }
  }
}

.componentLetter {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-rows: auto;
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  text-transform: uppercase;
  font-weight: bold;
  align-items: center;
  justify-items: center;

  @media (--viewport-md) {
    font-size: var(--font-size-26);
  }

  & > .letter {
    position: absolute;
    z-index: var(--z-index);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.letter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: var(--size-20);

  &::after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: var(--color-blue-700);
  }
}

.showLetter {
  animation: showLetter 0s linear forwards;
  animation-delay: var(--total-delay);
}

.letterAnimation {
  animation: flipBoardLetter var(--duration-150) linear forwards;
  animation-delay: var(--delay);
  transform-origin: center;
}

.nextLetter {
  clip-path: inset(50% 0 0 0);
  transform: rotateX(180deg);
  backface-visibility: hidden;
  animation: flipBoardLetter var(--duration-150) linear forwards;
  animation-direction: reverse;
  animation-delay: var(--delay);
  transform-origin: top;
}

@keyframes flipBoardLetter {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

@keyframes showLetter {
  0% {
    display: none;
  }

  100% {
    display: flex;
  }
}
