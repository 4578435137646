.component {
  --animation: none;

  display: flex;
  align-items: center;
  gap: var(--size-8);
  font-weight: var(--font-weight-400);

  &:focus {
    --animation: rotate var(--duration-250) linear;
  }

  & > .iconContainer {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.iconContainer {
  animation: var(--animation);
  animation-duration: var(--duration-400);
  animation-timing-function: var(--ease);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

@keyframes rotate {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}
