.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-125);
  }
}

.container {
  & > .slider {
    width: 100%;
    margin: 0 auto;
    overflow: visible;
  }
}

.slider {
  display: flex;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .slide {
    min-width: 85%;
    height: auto;

    @media (--viewport-sm) {
      min-width: 35%;
    }

    @media (--viewport-lg) {
      min-width: 20%;
    }
  }
}

.slide {
  overflow: visible !important;

  & > .slideLayout {
    height: 100%;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.headingContainer {
  display: grid;
  grid-template-columns: var(--size-48) auto;
  grid-template-rows: auto auto var(--size-80);
  gap: var(--size-24);

  @media (--viewport-xl) {
    transform: translateX(calc(-1 * var(--size-48)));
  }

  & > .line {
    grid-column: 1;
    grid-row: 1 / 4;
    width: var(--size-4);
    height: calc(100% - var(--size-20));
    margin-top: var(--size-20);
  }

  & > .headingLayout {
    grid-column: 2;
    grid-row: 1;
  }

  & > .introduction {
    grid-row: 2;
    max-width: 550px;
  }
}

.line {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-blue-900);
  transform: translateX(var(--size-12));

  @media (--viewport-md) {
    transform: none;
  }

  &::before {
    content: '';
    display: block;
    width: var(--size-16);
    height: var(--size-16);
    background-color: var(--color-blue-900);
    border-radius: 50%;
    transform: translateY(-1px);
  }
}
