.component {
  --opacity-underline: 0;

  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-48);
  padding: var(--size-32);
  background-color: var(--color-blue-200);
  border-radius: var(--radius-10);
  object-fit: cover;
  transition: scale var(--duration-400) ease;
  overflow: hidden;

  & > .titleLayout {
    position: static;
  }

  & > .jobCardCursorLayout {
    z-index: 1;
    position: absolute;
    width: var(--size-80);
    height: var(--size-80);
  }

  &:hover {
    --opacity-underline: 1;

    scale: 1.025;
  }
}

.componentTitle {
  position: relative;
  color: var(--color-blue-900);
  font-size: var(--font-size-38);
  font-size: var(--font-size-32-38);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-block);
  line-height: var(--line-height-block);
  overflow: hidden;

  &.hasLongWord {
    font-size: var(--font-size-32);
    font-size: var(--font-size-22-32);
  }

  & > .link {
    position: static;
  }
}

.link {
  position: relative;

  @media (--viewport-md) {
    cursor: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .underline {
    width: fit-content;
  }
}

.underline {
  position: relative;
  display: block;
  padding-bottom: var(--size-4);

  &::before {
    opacity: var(--opacity-underline);
    content: attr(data-content);
    position: absolute;
    top: 2px;
    left: 0;
    color: transparent;
    text-decoration: underline;
    text-decoration-color: var(--color-blue-900);
    text-decoration-thickness: 2px;
    transition: opacity var(--duration-250) ease;
  }
}

.componentMeta {
  display: flex;
  align-items: center;
  font-size: var(--font-size-18);
  font-size: var(--font-size-16-18);
  gap: var(--size-8);
  flex-wrap: wrap;

  & > .subsidiary {
    width: 100%;
  }

  & > .jobAreaTagLayout {
    width: auto;
  }
}

.subsidiaryContainer {
  & > .jobAreaTagLayout {
    width: fit-content;
  }
}
