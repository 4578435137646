.component {
  z-index: 0;
  position: relative;
  background-color: var(--color-white);
}

.jobAreaSlider {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .contentContainer {
    position: relative;
    height: calc(var(--container-height) - 100vh);
    width: 100%;
    top: 0;

    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .images {
    height: calc(var(--container-height) - 100vh);
    margin-right: unset;
    top: 0;

    @media (--viewport-md) {
      max-width: 40%;
      min-width: 40%;
      margin-right: var(--size-100);
      flex-grow: 0;
    }

    @media (--viewport-lg) {
      max-width: 45%;
      min-width: 45%;
    }
  }

  & > .jobAreaNavLayout {
    width: 100%;
    height: 100vh;
    top: 0;

    @media (--viewport-md) {
      width: var(--size-64);
      left: 0;
    }
  }
}

.images {
  position: sticky;
  display: flex;
  flex-direction: row;

  @media (--viewport-md) {
    position: relative;
    flex-direction: column;
  }

  & > .slideLayout {
    top: 0;
    height: 100vh;
  }
}

.contentContainer {
  & > .slideContentLayout {
    width: 100%;
    top: 0;
    height: 100vh;
  }
}

.componentSlide {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-200);

  & > .windowImage {
    width: 100%;
  }
}

.windowImage {
  border-radius: var(--size-300);
  overflow: hidden;
  aspect-ratio: 0.7 / 1;
  transform: scale(0.9);

  @media (max-height: 600px) {
    transform: scale(0.6);
  }

  @media (max-height: 815px) {
    transform: scale(0.7);
  }
}

.link {
  display: flex;
  gap: var(--size-4);
  align-items: center;
  justify-content: flex-start;

  & > .iconLayout {
    width: var(--size-64);
  }
}

.componentJobAreaSlideContent {
  position: sticky;
  height: 100vh;


  & > .jobAreaSlideLayout {
    position: absolute;
    top: 50%;
  }
}

.componentJobAreaSlide {
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  opacity: 0;
  transition: opacity var(--duration-400) ease-in-out;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  & > .jobAreaSlideContentTags {
    margin-bottom: var(--size-32);
  }
}

.jobAreaContentText {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-20);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
}

.jobAreaHeading {
  font-size: var(--font-size-100);
  font-size: var(--font-size-40-100);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
}

.jobAreaSlideContentTags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-12);
  opacity: 0;
  animation: slideAnimation var(--duration-400) ease forwards;

  &.active {
    animation: activeSlideAnimation var(--duration-400) ease forwards;
    animation-delay: var(--duration-500);
  }
}

.headingReveal {
  opacity: 0;
  will-change: opacity, transform;
  font-size: var(--font-size-32);
  animation: slideAnimation var(--duration-350) ease forwards;

  &.active {
    animation: activeSlideAnimation var(--duration-350) ease forwards;
    animation-delay: var(--duration-450);
  }

  & > .jobAreaHeading {
    margin-bottom: var(--size-24);
  }
}

.slideTextAndButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-48);
  opacity: 0;
  will-change: opacity, transform;
  animation: slideAnimation var(--duration-350) ease forwards;

  &.active {
    animation: activeSlideAnimation var(--duration-350) ease forwards;
    animation-delay: var(--duration-500);
  }
}

.componentJobAreaSliderNav {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--size-4);
  transform: translateX(-50%);

  @media (--viewport-md) {
    flex-direction: column;
  }

  & > .buttonLayout {
    width: var(--size-24);
    height: var(--size-8);
  }
}

.componentJobAreaSliderButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  &::after {
    content: '';
    width: 100%;
    height: var(--size-4);
    transform: scaleX(1);
    will-change: transform;
    transition: transform var(--duration-350) ease;
    background-color: var(--color-blue-300);
    border-radius: var(--radius-38);
  }

  &.isActive {
    &::after {
      background-color: var(--color-blue-600);
      transform: scaleX(1.2);
    }
  }
}

.componentLinkToArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-12);
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-400);

  & > .iconLayout {
    width: var(--size-40);
    margin-top: var(--size-4);
  }
}

@keyframes slideAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(calc(-1 * var(--size-32)));
  }
}

@keyframes activeSlideAnimation {
  0% {
    opacity: 0;
    transform: translateY(var(--size-32));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
