.component {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-blue-300);

  & > .iconLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}
