.component {
  display: flex;
  flex-direction: column;

  & > .form {
    flex: 1;
    width: 100%;
  }

  & > .error {
    margin-bottom: var(--size-12);
  }
}

.componentPrivacyStatement {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .formFieldCheckboxLayout {
    margin-bottom: var(--size-32);
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-20);

  & > .formFields {
    width: 100%;
  }

  & > .boardingPassLayout {
    @media (--viewport-lg) {
      margin-left: auto;
    }
  }
}

.formFields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-20);

  & > * {
    width: 100%;
  }

  & > .navigateContainer {
    width: 100%;
    margin-top: var(--size-32);
  }
}

.error {
  background-color: var(--color-blue-700);
  border-radius: var(--radius-4);
  color: var(--color-white);
  padding: var(--size-8);
}

.twoInputFields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-12);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    flex: 1;
    width: 100%;
  }
}

.sendApplicationButton {
  --translateX-button-label: 0;

  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-26);
  gap: var(--size-20);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }

  &:hover {
    --translateX-button-label: calc(-1 * var(--size-8));
  }

  &:disabled {
    opacity: 0.5;
  }

  & > .navigateSpan {
    order: 0;
  }
}

.navigateSpan {
  transform: translateX(var(--translateX-button-label));
  transition: transform var(--duration-250) ease;
}

.navigateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    order: 1;
    margin-left: auto;

    &:nth-child(2) {
      order: 0;
      margin-left: unset;
    }
  }
}

.sendIconContainer {
  border-radius: var(--radius-8);
  background-color: var(--color-blue-600);
  color: var(--color-white);
  padding: var(--size-16);

  & > .sendIconLayout {
    width: var(--size-32);
    height: var(--size-32);
  }
}

.componentOptionalResume {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  & > .optionalResume {
    width: 100%;
  }
}

.showResumeCheckbox {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  font-family: var(--font-family-alt);
  padding-bottom: var(--size-8);

  & > .checkboxLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.optionalResume {
  display: none;

  &.showOptionalResume {
    display: block;
  }
}

.componentErrorMessage {
  display: flex;
  align-items: center;
  gap: var(--size-4);
  padding: var(--size-12) 0;
  font-size: var(--font-size-16-18);
  color: var(--color-red-500);

  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}
