.component {
  --scale: 1;
  --opacity: 1;
  --button-background: var(--color-white);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    --scale: 1.05;
    --button-background: var(--color-blue-200);
  }

  &.playing {
    pointer-events: none;
    --scale: 0.85;
    --opacity: 0;
  }

  & > .playButtonCircle {
    width: 25%;
    max-width: var(--size-100);
    max-height: var(--size-100);

    @media (--viewport-md) {
      max-width: var(--size-125);
      max-height: var(--size-125);
    }
  }
}

.playButtonCircle {
  aspect-ratio: 1;
  opacity: var(--opacity);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-12) 0;
  color: var(--color-blue-900);
  background-color: var(--button-background);
  box-shadow: 0 7px 20px rgba(0, 91, 130, 0.12);
  border-radius: 50%;
  transition: var(--duration-400) var(--ease-out-expo);
  transform: scale(var(--scale));
  overflow: hidden;

  & > .iconContainer {
    width: var(--size-24);
    height: var(--size-24);

    @media (--viewport-md) {
      width: var(--size-32);
      height: var(--size-32);
    }
  }
}

.iconContainer {
  transform: translateX(3px);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
