.component {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .heroLayout {
    z-index: -2;
    position: fixed;
    width: 100%;
    height: 100vh;
    height: 100lvh;
    top: 0;
    overflow: visible;
  }

  & > .contentBorderLayout {
    z-index: -1;
    width: 100%;
    height: 100vh;
    height: 100svh;
  }
}

.componentSubpageCards {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  text-align: center;

  @media (--viewport-md) {
    flex-direction: row;
  }

  @media (--viewport-lg) {
    gap: var(--size-32);
  }

  & > * {
    flex: 1;
  }
}

.componentCard {
  --transform-icon: 0;

  z-index: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: var(--size-24);
  border-radius: var(--radius-10);
  background-color: var(--color-blue-200);
  overflow: hidden;
  aspect-ratio: 5 / 7;

  @media (--viewport-md) {
    aspect-ratio: 7 / 5;
  }

  &:hover {
    --transform-icon: var(--size-12);
  }

  & > .imageContainer {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .buttonLayout {
    z-index: 1;
    position: static;
    width: max-content;
    margin: var(--size-24);
  }
}

.imageContainer {
  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.componentCardButton {
  position: relative;
  display: flex;
  gap: var(--size-12);
  align-items: center;
  padding: var(--size-12) var(--size-20);
  padding-right: var(--size-24);
  color: var(--color-white);
  border: 2px solid var(--color-white);
  border-radius: var(--radius-100);

  @media (--viewport-md) {
    gap: var(--size-16);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .iconContainer {
    margin-top: auto;
    width: var(--size-40);
    height: var(--size-40);
  }
}

.buttonLabel {
  font-size: var(--font-size-22-32);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
}

.iconContainer {
  transform: translateX(var(--transform-icon));
  transition: transform var(--duration-250) var(--ease-out-quad);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
