.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .splitFlapBoard {
    width: 100%;
  }
}

.splitFlapBoard {
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: var(--color-blue-900);
  padding: var(--size-40) 0;
  border-radius: var(--radius-20);
}

.componentFlipBoard {
  display: flex;
  border-bottom: 1px solid var(--color-black);
  padding: var(--size-20) var(--size-40);

  &:last-child {
    border-bottom: none;
  }

  & > .domainLayout {
    margin-left: 1px;
  }

  & > .splitFlapLayout {
    margin-left: var(--size-20);
  }
}

.componentLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  padding: 0 var(--size-8);
  object-fit: cover;

  & > .iconLayout {
    height: var(--size-16);
    width: var(--size-32);

    @media (--viewport-md) {
      width: var(--size-64)
    }
  }
}
