.component {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: var(--size-80) 0;

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: flex-start;
  }

  &.imageAlignmentLeft {
    @media (--viewport-lg) {
      flex-direction: row-reverse;
    }
  }

  & > .blockQuoteLayout {
    flex: 2;
  }

  & > .mediaWindowLayout {
    flex: 1.5;
    flex-shrink: 0;
    width: 100%;
    height: max-content;

    @media (--viewport-sm) {
      max-width: 350px;
    }

    @media (--viewport-lg) {
      max-width: 450px;
    }
  }
}

.componentBlockQuote {
  color: var(--color-blue-300);
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
  letter-spacing: var(--letter-spacing-heading);
  quotes: none;

  @media (--viewport-md) {
    padding: 0 var(--size-64);
  }

  @media (--viewport-lg) {
    padding: 0 var(--size-80);
  }
}

.quoteHighlightElement {
  --opacity: 0;
  --progress: 0%;
  --from: rgba(7, 43, 69, var(--opacity)) var(--progress);
  --to: var(--color-blue-100) calc(var(--progress) + 1%);

  background-image: linear-gradient(90deg, var(--from), var(--to));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.quoteParagraph {
  &:first-of-type {
    &::before {
      content: '"';
      display: inline-block;
    }
  }

  &:last-of-type {
    &::after {
      content: '"';
      display: inline-block;
    }
  }
}

.source {
  display: flex;
  align-items: center;
  gap: var(--size-20);
  color: var(--color-blue-900);
  font-size: var(--font-size-20);
  font-size: var(--font-size-18-22);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);

  &::before {
    content: '';
    display: inline-block;
    width: var(--size-16);
    height: var(--size-16);
    background-color: var(--color-blue-500);
    border-radius: 50%;
  }
}
