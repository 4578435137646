.component {
  --background-transition-duration: var(--duration-1500);
  --background-scaleY: 0;

  z-index: 0;
  position: relative;
  transform: translateY(calc(-1 * var(--size-80)));
  transition:
    transform var(--duration-400) var(--ease-out-quad),
    box-shadow var(--duration-400) ease;

  &.isVisible {
    --background-transition-duration: var(--duration-400);

    transform: translateY(0);
  }

  &.isSolid {
    --background-scaleY: 1;
    --header-color: var(--color-blue-900);
    --header-color-logo: var(--color-blue-500);
    --header-color-seperator: var(--color-blue-200);
  }

  &.isScrolledDown {
    box-shadow: var(--box-shadow-black);
  }

  & > .backgroundLayout {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: var(--size-80);
    top: 0;
  }
}

.headerWhite {
  --background-scaleY: 1;
  --header-color: var(--color-blue-900);
  --header-color-logo: var(--color-blue-500);
  --header-color-seperator: var(--color-blue-200);
}

.headerTransparent {
  --header-color: var(--color-blue-900);
  --header-color-logo: var(--color-blue-500);
  --header-color-seperator: var(--color-white);
}

.headerTransparentWhite {
  --header-color: var(--color-white);
  --header-color-logo: var(--color-white);
  --header-color-seperator: var(--color-blue-700);
}

.headerTransparentDarkBlue {
  --header-color: var(--color-blue-900);
  --header-color-logo: var(--color-white);
  --header-color-seperator: var(--color-blue-700);
}

.componentBackground {
  --submenu-background-scaleY: 0;

  pointer-events: auto;
  display: grid;
  grid-template-rows: var(--size-80) var(--size-100);
  grid-template-areas:
    'menu'
    'submenu';
  transform: scaleY(var(--background-scaleY));
  transition-property: transform;
  transition-duration: var(--background-transition-duration);
  transition-timing-function: ease;
  transform-origin: top;

  &::before {
    content: '';
    display: block;
    grid-area: menu;
    width: 100%;
    background-color: var(--color-white);
  }

  &::after {
    content: '';
    display: block;
    grid-area: submenu;
    width: 100%;
    background-color: var(--color-blue-200);
    transform: scaleY(var(--submenu-background-scaleY));
    transform-origin: top;
    transition: transform var(--duration-600) ease;
  }
}

.navigationBarWrapper {
  & > .navigationBarContainer {
    height: var(--size-80);
  }
}

.navigationBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-12);

  & > .menuContainer {
    height: 100%;
  }
}

.logoContainer {
  color: var(--header-color-logo);
  transition: color var(--duration-150) var(--ease-out-circ);
}

.menuContainer {
  pointer-events: auto;
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .menuSeperator {
    width: 2px;
    height: var(--size-48);
  }
}

.menuSeperator {
  background: var(--header-color-seperator);
  transition: background var(--duration-400) ease;
}

.languageAndLoginContainer {
  display: flex;
  overflow: hidden;

  &:last-child {
    /* For usability this is better than using gaps, but you want to ignore the last child */
    /* stylelint-disable-next-line kaliber/layout-related-properties */
    margin-right: calc(-1 * var(--size-24));
  }
}

.componentMenu {
  display: flex;
}

.componentMenuItem {
  pointer-events: auto;
  display: flex;
  align-items: center;
}

.componentMenuItemLink {
  --color-hover: transparent;

  padding: 0 var(--size-24);

  &:hover,
  &.isActive {
    --color-hover: var(--color-blue-600);
  }

  & > .menuLabelLayout {
    height: var(--size-80);
  }
}

.componentMenuItemWithDropdown {
  --color-hover: transparent;

  padding: 0 var(--size-24);

  &.thisSubmenuIsActive {
    --color-hover: var(--color-blue-600);
  }

  & > .dropdown {
    width: 100%;
  }
}

.componentDropdownLabel {
  & > .menuLabelLayout {
    height: var(--size-80);
  }
}

.dropdown {
  pointer-events: none;

  & > .submenuLayout {
    width: 100%;
  }
}

.componentMenuItemLabel {
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-8);
  color: var(--header-color);
  font-family: var(--font-family-base);
  font-size: var(--size-16);
  font-weight: var(--font-weight-300);
  white-space: nowrap;
  transition: color var(--duration-400) var(--ease-out-circ);

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: var(--size-4);
    background-color: var(--color-hover);
    bottom: 0;
  }

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}
