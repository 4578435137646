.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

.componentItemCard {
  display: flex;
  flex-direction: column;
  color: var(--color-blue-900);
  background-color: var(--color-blue-200);
  border-radius: var(--radius-10);
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-32) var(--size-48) var(--size-40) var(--size-32);
  transition: padding var(--duration-600) var(--ease-out-expo);
  text-align: left;

  @media (--viewport-md) {
    padding: var(--size-48) var(--size-80) var(--size-52) var(--size-64);
  }

  &.isActive {
    padding-bottom: var(--size-32);
  }

  & > .headingLayout {
    flex: 2;
  }

  & > .iconWrapper {
    flex: 1;
  }
}

.iconWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > .iconContainer {
    width: var(--size-32);
    height: var(--size-32);

    @media (--viewport-md) {
      width: var(--size-40);
      height: var(--size-40);
    }
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  transform: rotate(0);
  transition: transform var(--duration-600) var(--ease-out-expo);

  &.isActive {
    transform: rotate(-180deg);
  }
}

.answerContainer {
  opacity: 0;
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'text';
  padding: 0 var(--size-48) 0 var(--size-32);
  transition: var(--duration-600) var(--ease-out-expo);
  transition-property: opacity, grid-template-rows, padding;
  overflow: hidden;

  @media (--viewport-md) {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'text ...';
    padding: 0 var(--size-64);
  }

  &.isActive {
    opacity: 1;
    pointer-events: auto;
    grid-template-rows: 1fr;
    padding-bottom: var(--size-40);

    @media (--viewport-md) {
      padding-bottom: var(--size-64);
    }
  }

  & > .portableTextLayout {
    grid-area: text;
  }
}
