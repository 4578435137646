.component {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-100);
  border-radius: var(--radius-8);

  @media (--viewport-lg) {
    flex-direction: row;
  }

  & > .boardingPassDivider {
    width: 100%;

    @media (--viewport-lg) {
      width: var(--size-80);
    }
  }
}

.boardingPassLogo {
  padding: var(--size-32) var(--size-32) 0;

  @media (--viewport-lg) {
    padding: var(--size-32) 0 var(--size-32) var(--size-32);
  }

  & > .iconLayout {
    width: var(--size-52);
  }
}

.boardingPassLeft {
  padding: var(--size-32);
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.boardingPassRight {
  padding: var(--size-32);
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    padding: var(--size-32) var(--size-64) var(--size-32) 0;
  }
}

.boardingPassDivider {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: var(--size-24);
    height: var(--size-24);
    background-color: var(--color-white);
    top: calc(-1 * var(--size-12));
    left: calc(-1 * var(--size-12));
    border-radius: 50%;

    @media (--viewport-lg) {
      left: var(--size-28);
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    width: var(--size-24);
    height: var(--size-24);
    background-color: var(--color-white);
    bottom: calc(-1 * var(--size-12));
    left: unset;
    right: calc(-1 * var(--size-12));
    border-radius: 50%;

    @media (--viewport-lg) {
      left: var(--size-28);
      right: unset;
    }
  }

  & > .boardingPassDividerLine {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;

    @media (--viewport-lg) {
      width: 1px;
      height: 100%;
      left: var(--size-40);
    }
  }
}

.boardingPassDividerLine {
  background-image: linear-gradient(to right, var(--color-blue-900) 60%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: var(--size-20) var(--size-4);
  background-repeat: repeat-x;

  @media (--viewport-lg) {
    background-image: linear-gradient(to bottom, var(--color-blue-900) 60%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: var(--size-4) var(--size-20);
    background-repeat: repeat-y;
  }
}

.componentRow {
  display: flex;
  gap: var(--size-52);

  @media not (--viewport-lg) {
    gap: var(--size-24);
    flex-wrap: wrap;
  }
}

.componentTitleAndValue,
.componentTitleAndValueBig,
.componentTitleAndValueWithoutCapitals,
.componentBoardingPriority {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-16);
  line-height:var(--line-height-normal);
}

.itemValue {
  text-transform: uppercase;
  font-weight: var(--font-weight-700);
}

.itemValueBig {
  font-size: var(--font-size-22);
  text-transform: uppercase;
  font-weight: var(--font-weight-700);
}

.itemValueWithoutCapitals {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-700);
}

.componentBoardingPriority {
  & > .priorityContainer {
    margin-top: var(--size-4);
  }
}

.priorityContainer {
  display: flex;
  border-radius: var(--radius-8);
  overflow: hidden;
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-700);
}

.priorityItem {
  background-color: var(--color-white);
  padding: 0 var(--size-12);
  color: var(--color-blue-500);

  &:not(:last-child) {
    border-right: 2px solid var(--color-blue-500);
  }

  &.active {
    background-color: var(--color-blue-500);
    color: var(--color-white);
  }
}

