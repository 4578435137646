.component {
  display: flex;
  flex-direction: column-reverse;
  color: var(--color-white);
  padding: var(--size-100) 0 var(--size-24);
  gap: var(--size-32);
  background-color: var(--color-blue-500);

  @media (--viewport-md) {
    padding: var(--size-200) 0 var(--size-24);
    flex-direction: row;
    align-items: center;
  }

  & > .imageLayout {
    width: 100%;
    max-width: 200px;
    height: 100%;
    margin-left: auto;

    @media (--viewport-lg) {
      max-width: 600px;
      margin-left: unset;
    }
  }

  & > .titleLayout {
    @media (--viewport-lg) {
      justify-self: start;
    }
  }
}

.componentTitle {
  font-size: var(--font-size-70);
  font-size: var(--font-size-40-70);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);

  @media (--viewport-md) {
    font-size: var(--font-size-120);
    font-size: var(--font-size-70-120);
    line-height: var(--line-height-heading);
  }
}

.titleHighlight {
  color: var(--color-blue-900);
}
